import React, { useState, useEffect } from 'react';

//MUI
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';
import { useWidth } from '../../utils/widthSelector';

//Icons
import SearchAndCreate from '../../components/input/searchAndCreate';
import VisibilityIcon from '@mui/icons-material/Visibility';

import mainURL from '../../config/environment';
import $ from 'jquery';

import UpdateScaffoldInventoryDialog from '../../components/dialogs/updateScaffoldInventoryDialog';
import UpdateSupplyInventoryDialog from '../../components/dialogs/updateSupplyInventoryDialog';
import UpdatesAdvancedSearchDialog from '../../components/dialogs/updatesAdvancedSearch';
import UpdateDetails from '../../components/dialogs/updateDetailsDialog';
import UpdatesPopover from '../../components/popovers/inventoryUpdates';

const emptyData = {
  id: '',
  name: '',
  scaffoldId: '',
  supplyId: '',
  equipmentId: '',
  type: '',
  invoice: '',
  quantity: 0,
  createdBy: 0,
  createdOn: '2024-05-22T21:23:23.065Z',
  registeredOn: '2024-05-22T21:23:23.065Z',
  createdByUser: '',
  comments: '',
};

const options = [];

export default function InventoryUpdates(props) {
  //Data management

  const [selectedData, setSelectedData] = useState(emptyData);
  const [filteredData, setFilteredData] = useState([]);
  const [showScaffolds, setShowScaffolds] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  const breakpoint = useWidth();

  const columns = [
    {
      headerName: 'Fecha de registro',
      field: 'registeredOn',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {new Date(params.row.registeredOn).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      headerName: 'Fecha de modificación',
      field: 'createdOn',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {new Date(params.row.createdOn).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      headerName: 'Item',
      field: 'name',
      flex: 2,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Tipo',
      field: 'type',
      //flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Cantidad',
      field: 'quantity',
      //flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    {
      headerName: 'Factura',
      field: 'invoice',
      //flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    {
      headerName: 'Comentarios',
      field: 'comments',
      flex: 2,
      breakpoints: ['md', 'lg', 'xl'],
    },
    /* {
      headerName: 'Realizado por',
      field: 'createdByUser',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    }, */
    {
      headerName: 'Comentarios',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handleSelect(params.row)}>
          <VisibilityIcon color="action" />
        </IconButton>
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  const [anchor, setAnchor] = useState(null);

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  //Dialog management

  const [updateScaffoldInventoryDialog, setUpdateScaffoldInventoryDialog] = useState(false);
  const [updateSupplyInventoryDialog, setUpdateSupplyInventoryDialog] = useState(false);
  const [advancedSearchDialog, setAdvancedSearchDialog] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const handleOpenDialog = (dialog) => (event) => {
    console.log(dialog);
    switch (dialog) {
      case 'refresh':
        setSearchText('');
        setRefresh(!refresh);
        break;

      case 'supplies':
        setUpdateSupplyInventoryDialog(true);
        break;

      case 'scaffolds':
        setUpdateScaffoldInventoryDialog(true);
        break;

      case 'create':
        if (showScaffolds) setUpdateScaffoldInventoryDialog(true);
        else setUpdateSupplyInventoryDialog(true);
        break;

      case 'search':
        setAdvancedSearchDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  const handleOpenRealDialog = (dialog) => {
    switch (dialog) {
      case 'refresh':
        setSearchText('');
        setRefresh(!refresh);
        break;

      case 'supplies':
        setUpdateSupplyInventoryDialog(true);
        break;

      case 'scaffolds':
        setUpdateScaffoldInventoryDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  const handleCloseDialogs = () => {
    setUpdateScaffoldInventoryDialog(false);
    setUpdateSupplyInventoryDialog(false);
    setAdvancedSearchDialog(false);
    setInfoDialog(false);
  };

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  const handleSelect = (data) => (event) => {
    setSelectedData(data);
    setInfoDialog(true);
  };

  const handleChangeView = () => {
    setShowScaffolds(!showScaffolds);
  };

  //Page management
  const [hasNextPage, setHasNextPage] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = (event) => {
    setPage(event.page + 1);
    setPageSize(event.pageSize);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    //handleShowNotification('info', 'Cargando movimientos de inventario');
    const type = showScaffolds ? 'andamios' : 'repuestos';
    $.ajax({
      method: 'GET',
      url: `${mainURL}inventory-log/get-all?pageNumber=${page}&pageSize=${pageSize}&type=${type}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res.results;
        if (isSubscribed) {
          setData(aux);
          setFilteredData(aux);
          setHasNextPage(res.hasNext);
          setRowCount(res.totalItemCount);
          // handleShowNotification('success', 'Movimientos cargados con éxito');
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });

    return () => (isSubscribed = false);
  }, [refresh, page, pageSize, showScaffolds]);

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => `${f.name} ${f.reference}`.toLowerCase().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <UpdateSupplyInventoryDialog
        refresh={refresh}
        open={updateSupplyInventoryDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <UpdateScaffoldInventoryDialog
        refresh={refresh}
        open={updateScaffoldInventoryDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <UpdatesPopover
        open={anchor}
        handleClose={handlePopoverClose}
        handleOpenDialog={handleOpenRealDialog}
      />
      <UpdatesAdvancedSearchDialog
        refresh={refresh}
        open={advancedSearchDialog}
        setRefresh={setRefresh}
        setFilteredData={setFilteredData}
        setData={setData}
        setRowCount={setRowCount}
        setHasNextPage={setHasNextPage}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <UpdateDetails open={infoDialog} data={selectedData} handleClose={handleCloseDialogs} />
      <Grid
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: '1rem 0' }}
        spacing={2}
        container
      >
        <Grid item md={8} container>
          <ToggleButtonGroup exclusive value={showScaffolds} onChange={handleChangeView}>
            <ToggleButton value={false}>
              <Typography variant="subtitle1">Repuestos</Typography>
            </ToggleButton>
            <ToggleButton value={true}>
              <Typography variant="subtitle1">Andamios</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            showAdvanceSearchIcon={data.length === filteredData.length}
            title={'Buscar en esta página'}
            handleOpenDialog={handleOpenDialog}
            handleSearch={handleSearch}
            searchText={searchText}
            options={options}
            showRefreshIcon
            permission={31}
          />
        )}
      </Grid>

      <Grid container height={'70vh'} sx={{ pb: 5 }}>
        <DataGrid
          columns={columns.filter((m) => m.breakpoints.includes(breakpoint))}
          paginationModel={{ page: page - 1, pageSize: pageSize }}
          paginationMeta={{ hasNextPage: hasNextPage }}
          onPaginationModelChange={handlePageChange}
          pageSizeOptions={[100, 200, 300]}
          onRowClick={handleSelect}
          paginationMode="server"
          disableColumnMenu
          rowCount={rowCount}
          rows={filteredData}
        />
      </Grid>
    </Box>
  );
}
