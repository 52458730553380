import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { CheckCircle, Clear } from '@mui/icons-material';

import $ from 'jquery';
import mainURL from '../../config/environment';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';

const emptyScaffold = {
  scaffoldId: '',
  quantity: '',
};

const emptyModel = {
  remissionId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  scaffoldCategoryId: '',
  comment: '',
  scaffolds: [],
};

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}scaffold-category/get-all`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      const aux = res.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      if (isSubscribed) setCategories(aux);
    });
    return () => (isSubscribed = false);
  }, []);
  return categories;
};

const useScaffolds = (categoryId) => {
  const [scaffolds, setScaffolds] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}scaffold/get-all?category=${categoryId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) setScaffolds(res);
    });
    return () => (isSubscribed = false);
  }, [categoryId]);
  return scaffolds;
};

export default function ScaffoldSurveyInput(props) {
  const [selectedCategory, setSelectedCategory] = useState('');

  const categories = useCategories();
  const scaffolds = useScaffolds(selectedCategory);

  const [selectedReference, setSelectedReference] = useState(null);
  const [scaffold, setScaffold] = useState(emptyScaffold);
  const [model, setModel] = useState(emptyModel);
  const [surveyArray, setSurveyArray] = useState([]);
  const [modelQuestions, setModelQuestions] = useState([]);

  const [isFormComplete, setFormComplete] = useState(false);

  const handleScaffoldChange = (newValue) => {
    if (newValue !== null) {
      setSelectedReference(newValue);
      setScaffold({
        ...scaffold,
        scaffoldId: newValue.id,
        name: newValue.name,
      });
    } else {
      setScaffold(emptyModel);
      setSelectedReference(null);
    }
  };

  const handleCategoryChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.options[target.selectedIndex].innerText;
    //console.log(name);
    setSelectedCategory(value);
    if (value === '3') {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
    setModel({
      ...model,
      scaffoldCategoryId: value,
      scaffoldCategoryName: name,
    });
    setModelQuestions(categories.filter((m) => m.id === parseInt(value))[0]?.questions);
  };

  const handleQuantityChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setScaffold({
      ...scaffold,
      [name]: value,
    });
  };

  const handleValidateCategory = (event) => {
    setFormComplete(true);
    setModel({ ...model, comment: JSON.stringify(surveyArray) });
  };

  /*  const handleSubmit = () => {
    props.handleAdd(scaffold);
    setScaffold(emptyModel);
    setSelectedReference(null);
  }; */

  const handleAddScaffold = (event) => {
    const a = [...model.scaffolds];

    a.push(scaffold);
    setModel({ ...model, scaffolds: a });

    setScaffold(emptyScaffold);
    setSelectedReference(null);
  };

  const handleDeleteScaffold = (index) => () => {
    let a = [...model.scaffolds];
    a.splice(index, 1);

    setModel({ ...model, scaffolds: a });
  };

  const handleSurveyChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const obj = { Question: name, Answer: value };

    const values = [...surveyArray];

    const idx = values.findIndex((m) => m.Question === name);

    if (idx === -1) {
      values.push(obj);
    } else {
      values.splice(idx, 1, obj);
    }

    setSurveyArray(values);
  };

  const handleSubmit = (event) => {
    props.handleAdd(model);
    setModel(emptyModel);
    setScaffold(emptyScaffold);
    setSurveyArray([]);
    setModelQuestions([]);
    setSelectedCategory('');
    setSelectedReference(null);
  };

  return (
    <Grid container alignItems={'center'} spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h6">Andamios</Typography>
      </Grid>
      <Grid item sm={12}>
        <FormControl fullWidth required sx={{ mt: 1 }}>
          <InputLabel variant="standard">{'Categoría'}</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            name="scaffoldCategoryId"
            variant="standard"
            native
          >
            <option value="" />
            {categories.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {selectedCategory !== '' ? (
        <Grid item sm={12} container sx={{ p: 2 }} spacing={2}>
          {modelQuestions.map((question, index) => (
            <Grid item sm={6} key={index}>
              {question.type === 'Select' ? (
                <FormControl fullWidth required>
                  <InputLabel variant="standard">{question.question}</InputLabel>
                  <Select
                    value={surveyArray.filter((m) => m.Question === question.question)[0]?.Answer}
                    onChange={handleSurveyChange}
                    name={question.question}
                    variant="standard"
                    native
                  >
                    <option value=""></option>
                    {question.options.map((opt) => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl fullWidth required>
                  <TextField
                    type="text"
                    onChange={handleSurveyChange}
                    name={question.question}
                    value={
                      surveyArray.filter((m) => m.Question === question.question)[0]?.Answer ?? ''
                    }
                    label={question.question}
                    variant="standard"
                  />
                </FormControl>
              )}
            </Grid>
          ))}
          {!isFormComplete ? (
            <Grid item sm={6} container justifyContent={'center'}>
              <IconButton onClick={handleValidateCategory} disabled={isFormComplete}>
                <Tooltip title="Validar categoría">
                  <CheckCircle color={isFormComplete ? 'action' : 'primary'} />
                </Tooltip>
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      {isFormComplete ? (
        <Grid item sm={12} container spacing={2} alignItems={'baseline'}>
          <Grid item sm={6}>
            <FormControl required fullWidth>
              <Autocomplete
                id="tags-standard"
                options={scaffolds.filter(
                  (x) => !model.scaffolds.map((m) => m.scaffoldId).includes(x.id),
                )}
                getOptionLabel={(opt) => opt.name}
                value={selectedReference}
                onChange={(event, newValue) => {
                  handleScaffoldChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Andamios" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item sm={4}>
            <FormControl required fullWidth>
              <TextField
                label={'Cantidad'}
                onChange={handleQuantityChange}
                value={scaffold.quantity}
                variant="standard"
                name="quantity"
                margin="dense"
                type="number"
                fullWidth
                autoFocus
                required
              />
            </FormControl>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={handleAddScaffold} disabled={scaffold === emptyScaffold}>
              <Tooltip title="Agregar andamio">
                <CheckCircle color={scaffold === emptyScaffold ? 'action' : 'primary'} />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      ) : null}

      <Grid item sm={12} container rowSpacing={2} maxHeight={200} sx={{ overflow: 'auto' }}>
        <List sx={{ width: '100%' }}>
          {model.scaffolds.map((sca, index) => (
            <ListItem item sm={12} key={sca.id}>
              <ListItemText primary={sca.name} secondary={sca.quantity} />
              <ListItemSecondaryAction>
                <IconButton onClick={handleDeleteScaffold(index)}>
                  <Clear />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item sm={12} container justifyContent={'flex-end'}>
        <Button onClick={handleSubmit} disabled={!isFormComplete || model.scaffolds.length === 0}>
          Agregar
        </Button>
      </Grid>
    </Grid>
  );
}
