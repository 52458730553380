import React, { useState, useEffect } from 'react';

//MUI
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';

import { useNavigate } from 'react-router-dom';

//Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CreateDevolutionDialog from '../../components/dialogs/createDevolution';
import RenameConstructionDialog from '../../components/dialogs/renameGeneric';
import DeleteRemissionDialog from '../../components/dialogs/deleteGeneric';
import SearchAndCreate from '../../components/input/searchAndCreate';
import ConstructionPopover from '../../components/popovers/generic';

import { alquicomClientId } from '../../config/appConstants';
import mainURL from '../../config/environment';
import $ from 'jquery';

const emptyData = {
  id: '',
  number: 0,
  constructionName: '',
  createdOn: '',
};

const options = [];

const errorMessage =
  'No se puede borrar esta devolución porque hay alquileres que dependen en ella';

export default function Devolutions(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  //Navigation
  const navigate = useNavigate();

  const columns = [
    {
      headerName: 'Número',
      field: 'number',
      flex: 1,
    },
    {
      headerName: 'Obra',
      field: 'constructionName',
      flex: 1,
    },
    {
      headerName: 'Fecha de creación',
      field: 'createdOn',
      flex: 1,
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {new Date(params.row.createdOn).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      headerName: 'Opciones',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
    },
  ];

  //Dialog management
  const [createDialog, setCreateDialog] = useState(false);
  const [renameDialog, setRenameDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleCloseDialogs = () => {
    setRenameDialog(false);
    setDeleteDialog(false);
    //setUpdateDialog(false);
    setCreateDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        navigate(`/devoluciones/crear`);
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'rename':
        setRenameDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => {
    if (data.row !== selectedData)
      navigate(`/devoluciones/${data.id}`); //setSelectedData(data.row);
    else setSelectedData(emptyData);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    handleShowNotification('info', 'Cargando devoluciones');
    $.ajax({
      method: 'GET',
      url: mainURL + 'devolution/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res.filter((m) => m.clientId !== alquicomClientId);
        if (isSubscribed) {
          setData(aux);
          handleShowNotification('success', 'Devoluciones cargadas con éxito');
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh]);

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => f.number.toString().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <ConstructionPopover
        open={anchor}
        showDeleteOption
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
        setRenameDialog={setRenameDialog}
      />
      <CreateDevolutionDialog
        type="devolution"
        title="Devolución"
        refresh={refresh}
        open={createDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <RenameConstructionDialog
        refresh={refresh}
        open={renameDialog}
        setRefresh={setRefresh}
        name={selectedData.number}
        title={'Renombrar devolucion'}
        handleClose={handleCloseDialogs}
        renameURL={`devolution/${selectedData.id}/rename`}
        handleShowNotification={handleShowNotification}
      />
      <DeleteRemissionDialog
        refresh={refresh}
        open={deleteDialog}
        title={'Eliminar devolución'}
        setRefresh={setRefresh}
        name={selectedData.number}
        errorMessage={errorMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`devolution/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: '1rem 0' }}
        spacing={2}
        container
      >
        <Grid item md={8}>
          <Typography variant="h4">{'Devoluciones'}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            handleOpenDialog={handleOpenDialog}
            title={'Buscar por número'}
            handleSearch={handleSearch}
            searchText={searchText}
            options={options}
            permission={23}
          />
        )}
      </Grid>
      <Grid container height={'70vh'} sx={{ pb: 5 }}>
        <DataGrid
          selectionModel={selectedData.id === '' ? [] : selectedData.id}
          onRowClick={handleSelect}
          rows={filteredData}
          columns={columns}
          disableColumnMenu
        />
      </Grid>
    </Box>
  );
}
