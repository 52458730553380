import React, { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import mainURL from '../../config/environment';
import $ from 'jquery';

const useCategories = () => {
  const [clients, setCategories] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}scaffold-category/get-all`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).done((res) => {
      const sorted = res.sort((a, b) => a.name.localeCompare(b.name));
      if (isSubscribed) setCategories(sorted);
    });
    return () => (isSubscribed = false);
  }, []);
  return clients;
};
export default function SelectScaffoldCategory(props) {
  const { handleChange } = props;
  const { value } = props;
  const { name } = props;

  const categories = useCategories();

  return (
    <FormControl fullWidth required>
      <InputLabel variant="standard">{'Categoría'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value="" />
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
