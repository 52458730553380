import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
//import InputLabel from "@mui/material/InputLabel";
import TextField from '@mui/material/TextField';
//import Select from "@mui/material/Select";

import $ from 'jquery';
import mainURL from '../../config/environment';

const useEquipments = (refresh, status) => {
  const [equipments, setEquipments] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}equipment/get-all?status=${status}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) setEquipments(res);
    });
    return () => (isSubscribed = false);
  }, [refresh, status]);
  return equipments;
};

export default function SelectMultipleEquipmentsDialog(props) {
  const { handleChange } = props;
  const { required } = props;
  const { refresh } = props;
  const { status } = props;
  const { title } = props;

  const equipments = useEquipments(refresh, status);

  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const handleMultipleChange = (newValue) => {
    setSelectedEquipments([...newValue]);
    handleChange([...newValue].map((m) => m.id));
  };

  return (
    <FormControl fullWidth required={required}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={equipments}
        getOptionLabel={(option) => `${option.name} - ${option.reference} - ${option.identifier}`}
        value={selectedEquipments}
        onChange={(event, newValue) => {
          console.log(newValue);
          handleMultipleChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={title}
            //placeholder="Favorites"
          />
        )}
      />
    </FormControl>
  );
}
