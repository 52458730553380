import React, { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//MUI-LAB
import $ from 'jquery';
import mainURL from '../../config/environment';
import SupplyQuantityInput from '../input/supplyQuantity';
import { Chip, Stack } from '@mui/material';

const emptyModel = {
  id: '',
  maintenanceId: '',
  date: '',
  technician: '',
  workedHours: '',
  supplies: [],
  diagnostic: '',
};

export default function UpdateMaintenanceDailyControlDialog(props) {
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const { refresh, controlId, maintenanceId, open } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
      maintenanceId: maintenanceId,
    });

    if (model.technician !== '') {
      setFormComplete(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let newModel = { ...model };
    if (model.supplyId === '' || model.supplyQuantity === 0) {
      newModel = { ...model, supplyId: null };
    }

    $.ajax({
      method: 'PUT',
      url: mainURL + `maintenance-daily-control/${controlId}/update`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(newModel),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Registro actualizado con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleAdd = (item) => {
    const a = [...model.supplies];
    a.push({ ...item, supplyName: item.name });
    setModel({ ...model, supplies: a });
  };

  const handleDelete = (index) => () => {
    let a = [...model.supplies];
    a.splice(index, 1);
    setModel({ ...model, supplies: a });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    if (controlId !== '' && open) {
      $.ajax({
        method: 'GET',
        url: `${mainURL}maintenance-daily-control/${controlId}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
          hostname: host,
        },
      }).done((res) => {
        console.log(res);
        setModel(res);
      });
    }
  }, [controlId, refresh, open]);

  return (
    <Dialog open={open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Actualizar registro'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <FormControl fullWidth required>
                <TextField
                  label={'Nombre del operario'}
                  onChange={handleChange}
                  value={model.technician}
                  variant="standard"
                  name="technician"
                  margin="dense"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth required>
                <TextField
                  label={'Horas laboradas'}
                  onChange={handleChange}
                  value={model.workedHours}
                  variant="standard"
                  name="workedHours"
                  margin="dense"
                  type="number"
                  fullWidth
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <SupplyQuantityInput handleAdd={handleAdd} />
            </Grid>
            {/* <Grid item sm={8}>
              <SelectSupply
                handleChange={handleChange}
                value={model.supplyId}
                refresh={refresh}
                name="supplyId"
              />
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth required>
                <TextField
                  label={'Cantidad'}
                  onChange={handleChange}
                  value={model.supplyQuantity}
                  variant="standard"
                  name="supplyQuantity"
                  margin="dense"
                  type="number"
                  fullWidth
                  required
                />
              </FormControl>
            </Grid> */}
            <Grid item xs={12} container>
              <Stack direction="row" spacing={1}>
                {model.supplies.map((supply, index) => (
                  <Chip
                    key={index}
                    label={`${supply.supplyName}  (${supply.quantity})`}
                    onDelete={handleDelete(index)}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <TextField
                  label="Diagnóstico"
                  rows={5}
                  multiline
                  name="diagnostic"
                  id={model.diagnostic}
                  value={model.diagnostic}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" disabled={!isFormComplete} onClick={handleSubmit}>
              Actualizar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
