import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import { Navigate, Route, Routes } from 'react-router-dom';

//Components
import BottomNavbar from '../../components/navigation/bottomNavbar';
import Navbar from '../../components/navigation/navbar';

import { generalEquipmentsId, heavyMachineryId } from '../../config/appConstants';

//Vi
import MaintenancelDailyControls from './maintenanceDailyControls';
import RentalDailyControls from './rentalDailyControls';
import MaintenanceTickets from './maintenanceTickets';
import MaintenanceReport from './maintenanceReport';
import Dashboard from '../../components/dashboard';
import RemissionDetails from './remissionDetails';
import CreateDevolution from './createDevolution';
import InventoryUpdates from './inventoryUpdates';
import CreateRemission from './createRemission';
import ClientDetails from './clientDetails2';
import Constructions from './constructions';
import Devolutions from './devolutions';
import Remissions from './remissions';
import Equipments from './equipments';
import Scaffolds from './scaffolds';
import Supplies from './supplies';
import Clients from './clients';
import Rentals from './rentals';
import Details from './details';
import Users from './users';

export default function Home(props) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navbar />
      <Box component="main" sx={{ flexGrow: 1, height: '93vh' }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="usuarios" element={<Users />} />
          <Route path="remisiones/:remissionId" element={<RemissionDetails type={'remission'} />} />
          <Route path="remisiones/crear" element={<CreateRemission />} />
          <Route path="devoluciones/crear" element={<CreateDevolution />} />
          <Route
            path="devoluciones/:devolutionId"
            element={<RemissionDetails type={'devolution'} />}
          />
          <Route path="clientes" element={<Clients />} />
          <Route path="repuestos" element={<Supplies />} />
          <Route path="andamios" element={<Scaffolds />} />
          <Route
            path="maquinaria"
            element={
              <Equipments categoryId={heavyMachineryId} title={'Maquinaria'} showCreateOption />
            }
          />
          <Route
            path="equipos"
            element={
              <Equipments categoryId={generalEquipmentsId} title={'Equipos'} showCreateOption />
            }
          />
          <Route path="obras" element={<Constructions />} />
          <Route
            path="obras/:constructionId/equipos"
            element={<Equipments title={'Equipos actualmente alquilados'} />}
          />
          <Route path="obras/:constructionId/alquileres" element={<Rentals />} />
          <Route path="mantenimientos" element={<MaintenanceTickets />} />
          <Route path="remisiones" element={<Remissions />} />
          <Route path="devoluciones" element={<Devolutions />} />
          <Route path="equipos/:equipmentId" element={<Details type="Equipment" />} />
          <Route path="andamios/:scaffoldId" element={<Details type="Scaffold" />} />
          <Route path="repuestos/:supplyId" element={<Details type="Supply" />} />

          <Route path="movimientos-inventario" element={<InventoryUpdates />} />

          <Route path="clientes/:clientId" element={<ClientDetails />} />
          <Route path="alquiler/:rentalId" element={<RentalDailyControls />} />
          <Route path="alquiler" element={<Navigate to="/remisiones" />} />
          <Route path="mantenimientos/:maintenanceId" element={<MaintenancelDailyControls />} />
          <Route path="mantenimientos/:maintenanceId/reporte" element={<MaintenanceReport />} />
          <Route path="clientes/:clientId/:constructionId" element={<ClientDetails />} />
        </Routes>
      </Box>
      <BottomNavbar />
    </Box>
  );
}
