import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SelectCategory from '../selects/selectCategory';

import $ from 'jquery';
import mainURL from '../../config/environment';
import SelectReturnStatus from '../selects/selectReturnStatus';

const emptyModel = {
  name: '',
  reference: '',
  categoryId: '',
  identifier: '',
  supplier: '',
  status: null,
};

export default function AdvancedSearchDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const { showSupplier } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;

    const baseUrl = showSupplier ? 'supply/' : 'equipment/';

    let searchString = '';

    if (model.categoryId !== '') searchString += `categoryId=${model.categoryId}&`;

    if (model.name !== '') searchString += `name=${model.name}&`;

    if (model.reference !== '') searchString += `reference=${model.reference}&`;

    if (model.identifier !== '') searchString += `identifier=${model.identifier}`;

    if (model.status !== null) searchString += `status=${model.status}`;

    $.ajax({
      method: 'GET',
      url: mainURL + `${baseUrl}search?${searchString}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        props.setFilteredData(res);
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Búsqueda avanzada'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <TextField
                  label={'Nombre'}
                  onChange={handleChange}
                  value={model.name}
                  variant="standard"
                  name="name"
                  margin="dense"
                  type="text"
                  fullWidth
                  autoFocus
                />
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormControl fullWidth>
                <TextField
                  label={'Referencia'}
                  onChange={handleChange}
                  value={model.reference}
                  variant="standard"
                  name="reference"
                  margin="dense"
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormControl fullWidth>
                <TextField
                  label={showSupplier ? 'Distribuidor' : 'Identificador numérico'}
                  onChange={handleChange}
                  value={showSupplier ? model.supplier : model.identifier}
                  variant="standard"
                  name={showSupplier ? 'supplier' : 'identifier'}
                  margin="dense"
                  type={showSupplier ? 'text' : 'number'}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <SelectCategory
                endpoint={showSupplier ? 'supply-category/get-all' : 'equipment-category/get-all'}
                value={model.categoryId}
                name="categoryId"
                handleChange={handleChange}
                refresh={props.open}
              />
            </Grid>
            <Grid item sm={6}>
              <SelectReturnStatus
                name="status"
                value={model.status ?? ''}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              Buscar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
