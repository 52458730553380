import React from 'react';

//Material UI

import Dialog from '@mui/material/Dialog';

export default function FullSizeImage(props) {
  const { image, name } = props;

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <img alt={name} src={image} width="100%" />
    </Dialog>
  );
}
