import React, { useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import $ from 'jquery';
import mainURL from '../../config/environment';
import { useWidth } from '../../utils/widthSelector';

const emptyModel = {
  name: '',
  prefix: '',
};

export default function CreateCategoryDialog(props) {
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const breakpoint = useWidth();

  const minWidth = useMemo(() => {
    if (breakpoint === 'xs') return '300px';
    else return '600px';
  }, [breakpoint]);

  const { refresh } = props;
  const { endpoint } = props;

  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    const nameArray = value.split(' ');
    let prefix = '';
    nameArray.forEach((element) => {
      prefix = prefix.concat(element.charAt(0).toUpperCase());
    });
    setModel({
      ...model,
      name: value,
      prefix: prefix,
    });

    if (model.name !== '') {
      setFormComplete(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    $.ajax({
      method: 'POST',
      url: mainURL + endpoint,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Categoría creada con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Crear categoría'}</DialogTitle>
      <DialogContent sx={{ minWidth: minWidth }}>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth required sx={{ mt: 1 }}>
            <TextField
              onChange={handleChange}
              variant="standard"
              value={model.name}
              label={'Nombre'}
              margin="dense"
              fullWidth
              type="text"
              name="name"
              autoFocus
              required
            />
          </FormControl>

          <Grid container direction="column" sx={{ p: '1rem 0' }}>
            <Typography variant="body1">Prefijo</Typography>
            <Typography variant="body2" color="textSecondary">
              {model.prefix}
            </Typography>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" disabled={!isFormComplete} onClick={handleSubmit}>
              Agregar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
