import React, { useState, useEffect } from 'react';

//MUI
import Typography from '@mui/material/Typography';
//import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';

import { useNavigate, useParams } from 'react-router-dom';

//Icons

//import AddEquipmentsToRemissionDialog from '../../components/dialogs/addEquipmentsToRemission';
import SearchAndCreate from '../../components/input/searchAndCreate';
// import DeleteRental from '../../components/dialogs/deleteGeneric';
//import OptionsPopover from '../../components/popovers/generic';
//import MoreVertIcon from '@mui/icons-material/MoreVert';

import { heavyMachineryId } from '../../config/appConstants';
import mainURL from '../../config/environment';
import $ from 'jquery';
import { useWidth } from '../../utils/widthSelector';

const emptyData = {
  id: '',
  equipmentId: '',
  returnStatus: '',
  rentalPrice: 0,
  equipmentName: '',
};

export default function Reservations(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const [filteredData, setFilteredData] = useState([]);

  //Navigation
  const navigate = useNavigate();
  const { constructionId } = useParams();

  const [data, setData] = useState([]);

  const breakpoint = useWidth();

  /* const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }; */

  const columns = [
    {
      headerName: 'Nombre',
      field: 'name',
      flex: 2,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Tipo',
      field: 'type',
      flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Cantidad',
      field: 'quantity',
      flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Remisión',
      field: 'remissionNumber',
      flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Estado de retorno',
      field: 'returnStatus',
      flex: 1,
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {params.row.returnStatus ?? 'No retornado aún'}
        </Typography>
      ),
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    /* {
      headerName: 'Opciones',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      //flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    }, */
  ];

  //Dialog management
  /*  const [createDialog, setCreateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleCloseDialogs = () => {
    setDeleteDialog(false);
    setCreateDialog(false);
  }; */

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  /*  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        setCreateDialog(true);
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  }; */

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  //Popover management
  /*  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  }; 

  const handlePopoverClose = () => {
    setAnchor(null);
  };*/

  const handleSelect = (data) => {
    console.log(data);
    if (data.row !== selectedData) {
      if (data.row.equipmentCategoryId === heavyMachineryId) navigate(`/alquiler/${data.id}`);
      setSelectedData(data.row);
    } else setSelectedData(emptyData);
  };

  //Rentals
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    handleShowNotification('info', 'Cargando alquileres');
    $.ajax({
      method: 'GET',
      url: `${mainURL}construction/${constructionId}/rentals`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setData(res);
          handleShowNotification('success', 'Alquileres cargados con éxito');
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [constructionId]);

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => f.name.toString().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      {/*  <OptionsPopover
        open={anchor}
        showDeleteOption
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
      /> */}
      {/*  <DeleteRental
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        name={`el equipo ${selectedData.equipmentName} de la remisión ${parent.number}`}
        title={'Eliminar equipo de la remisión'}
        handleClose={handleCloseDialogs}
        deleteURL={`rental/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      /> */}
      {/* <AddEquipmentsToRemissionDialog
        refresh={refresh}
        open={createDialog}
        remissionId={remissionId}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      /> */}

      <Grid
        justifyContent={'space-between'}
        sx={{ p: '1rem 0' }}
        alignItems={'center'}
        spacing={2}
        container
      >
        <Grid item md={8}>
          <Typography variant="h4">{'Equipos alquilados'}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            // handleOpenDialog={handleOpenDialog}
            buttonLabel={'Agregar'}
            title={'Buscar por equipo'}
            handleSearch={handleSearch}
            searchText={searchText}
            permission={200}
          />
        )}
      </Grid>

      <Grid container height={'70vh'} sx={{ pb: 5 }}>
        <DataGrid
          selectionModel={selectedData.id === '' ? [] : selectedData.id}
          onRowClick={handleSelect}
          rows={filteredData}
          columns={columns.filter((m) => m.breakpoints.includes(breakpoint))}
          disableColumnMenu
        />
      </Grid>
    </Box>
  );
}
