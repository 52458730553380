import React, { useEffect, useState } from 'react';

//Material UI
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';

//JQuery
import mainURL from '../../config/environment';
import $ from 'jquery';

export default function SupplyTicketsDialog(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [history, setHistory] = useState([]);

  const { supplyId } = props;
  const { name } = props;

  const handleNext = () => {
    if (activeStep !== history.length - 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    props.handleClose();
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;

    if (supplyId !== '') {
      $.ajax({
        method: 'GET',
        url: mainURL + `supply/${supplyId}/get-tickets`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).done((res) => {
        if (isSubscribed) {
          setHistory(res);
        }
      });
    }

    return () => (isSubscribed = false);
  }, [supplyId, props.open]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth={'lg'}>
      <DialogTitle id="alert-dialog-title">{'Historial de tickets'}</DialogTitle>
      <DialogContent sx={{ width: '35rem' }}>
        <DialogContentText>{`Historial de alquiler para el repuesto ${name}`}</DialogContentText>
        <Stepper activeStep={activeStep} nonLinear orientation="vertical">
          {history.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <Typography variant="subtitle1">{step.equipmentName}</Typography>
              </StepLabel>
              <StepContent>
                <Typography variant="body2" color="textSecondary">{`Desde el ${new Date(
                  step.startDate,
                ).toLocaleDateString()} hasta el ${new Date(
                  step.endDate,
                ).toLocaleDateString()}`}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    {activeStep !== history.length - 1 ? (
                      <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                        {'Siguiente'}
                      </Button>
                    ) : null}
                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Atrás
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {'Cerrar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
