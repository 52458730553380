import React from 'react';

//Material UI

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

//JQuery
import { List, ListItem, ListItemText } from '@mui/material';

export default function UpdateDetails(props) {
  //const [data, setData] = useState(emptyData);

  const { data } = props;

  const numberWithCommas = (number) => {
    if (number) return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /* useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    if (referenceId !== undefined && referenceId !== '') {
      $.ajax({
        method: 'GET',
        url: `${mainURL}rubber-reference/${referenceId}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).done((res) => {
        if (isSubscribed) setData(res);
      });
    }
    return () => (isSubscribed = false);
  }, [refresh, referenceId]); */
  //Submit

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle id="alert-dialog-title">{'Detalles del movimiento'}</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <List>
          <ListItem>
            <ListItemText primary={'Item'} secondary={data.name} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Fecha de registro'}
              secondary={new Date(data.registeredOn).toLocaleDateString()}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Fecha de modificación'}
              secondary={new Date(data.createdOn).toLocaleDateString()}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Realizado por'} secondary={data.createdByUser} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Tipo'} secondary={data.type} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Cantidad'} secondary={data.quantity} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Factura'} secondary={data.invoice} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Proveedor'} secondary={data.provider} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Costo'} secondary={`$ ${numberWithCommas(data.cost)}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Comentarios'} secondary={data.comments} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'}>
          <Button onClick={props.handleClose} color="primary">
            {'Cerrar'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
