import React, { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import SelectConstruction from '../selects/selectConstruction';

import $ from 'jquery';
import mainURL from '../../config/environment';
import RentalStatusPriceInput from '../input/rentalStatusPrice';

const emptyModel = {
  number: '',
  constructionId: '',
  rentals: [],
};

export default function CreateDevolutionDialog(props) {
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const [rentals, setRentals] = useState([]);
  const { refresh } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
    });

    if (model.number !== '' && model.constructionId !== '') {
      setFormComplete(true);
    }
  };

  const handleAddChip = (newValue) => {
    const values = [...model.rentals];
    if (values.findIndex((value) => value.rentalId === newValue.rentalId) === -1) {
      values.push(newValue);
      setModel({
        ...model,
        rentals: values,
      });
    }
  };

  const handleDeleteChip = (chip) => (event) => {
    const values = [...model.rentals];
    const index = values.findIndex((value) => value.rentalId === chip);
    values.splice(index);
    setModel({
      ...model,
      rentals: values,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    console.log(model);
    $.ajax({
      method: 'POST',
      url: mainURL + 'devolution',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Devolución creada con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    setFormComplete(false);
    props.setRefresh(!refresh);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    $.ajax({
      method: 'GET',
      url: `${mainURL}rental/get-all?constructionId=${model.constructionId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      const selectedIds = model.rentals.map((rent) => rent.rentalId);
      const aux = res.filter((item) => !selectedIds.includes(item.id));
      setRentals(aux);
    });
  }, [refresh, model.constructionId, model.rentals]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Crear devolución'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControl fullWidth required>
                <TextField
                  label={'Número'}
                  onChange={handleChange}
                  value={model.number}
                  variant="standard"
                  name="number"
                  margin="dense"
                  type="number"
                  step="1"
                  fullWidth
                  autoFocus
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <SelectConstruction
                handleChange={handleChange}
                value={model.constructionId}
                refresh={props.open}
                name="constructionId"
              />
            </Grid>
            <Grid item sm={12}>
              <Stack direction="row" spacing={1}>
                {model.rentals.map((rent) => (
                  <Chip
                    key={rent.rentalId}
                    label={rent.name}
                    onDelete={handleDeleteChip(rent.rentalId)}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <RentalStatusPriceInput rentals={rentals} handleAdd={handleAddChip} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button
              type="submit"
              disabled={!isFormComplete && model.rentals.length === 0}
              onClick={handleSubmit}
            >
              Agregar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
