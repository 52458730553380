import React, { useState, useEffect, useContext } from 'react';

//MUI
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';

//Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ConstructionIcon from '@mui/icons-material/Construction';
//import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';

import InventoryPopover from '../popovers/inventory';
import SectionsPopover from '../popovers/sections';
import { PermissionContext } from '../../config/permissionContext';

const sections = [
  {
    id: 'remissions',
    title: 'Remisiones',
    icon: <FileUploadIcon />,
    path: '/remisiones',
    permission: 22,
  },

  {
    id: 'devolutions',
    title: 'Devoluciones',
    icon: <FileDownloadIcon />,
    path: '/devoluciones',
    permission: 22,
  },
  {
    id: 'tickets',
    title: 'Mantenimientos',
    icon: <ConstructionIcon />,
    path: '/mantenimientos',
    permission: 27,
  },
  {
    id: 'clients',
    title: 'Clientes',
    icon: <EngineeringIcon />,
    path: '/clientes',
    permission: 9,
  },
  /*  {
    id: 'constructions',
    title: 'Obras',
    icon: <HouseSidingIcon />,
    path: '/obras',
    permission: 13,
  }, */
  {
    id: 'users',
    title: 'Usuarios',
    icon: <GroupIcon />,
    path: '/usuarios',
    permission: 1,
  },
];

export default function BottomNavbar() {
  const currentPath = window.location.pathname;
  const [value, setValue] = useState(currentPath);

  const permissions = useContext(PermissionContext);
  const navigate = useNavigate();

  //Profile popover management
  const [anchorInventory, setAnchorInventory] = useState(null);
  const [anchorSections, setAnchorSections] = useState(null);
  const handlePopoverOpen = (type) => (event) => {
    event.stopPropagation();
    switch (type) {
      case 'inventory':
        setAnchorInventory(event.currentTarget);
        break;
      case 'sections':
        setAnchorSections(event.currentTarget);
        break;
      default:
        setAnchorSections(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorInventory(null);
    setAnchorSections(null);
  };

  useEffect(() => {
    let isSubscribed = true;
    const mainRoute = `/${currentPath.split('/')[1]}`;
    if (isSubscribed) setValue(mainRoute);
    return () => (isSubscribed = false);
  }, [currentPath]);

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <InventoryPopover open={anchorInventory} handleClose={handlePopoverClose} />
      <SectionsPopover open={anchorSections} handleClose={handlePopoverClose} />
      <BottomNavigation
        showLabels
        sx={{ display: { xs: 'none', md: 'flex' } }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(newValue);
        }}
      >
        <BottomNavigationAction value={'/'} label="Inicio" icon={<DashboardIcon />} />
        <BottomNavigationAction
          onClick={handlePopoverOpen('inventory')}
          label="Inventario"
          icon={<InventoryIcon />}
        />
        {sections.map((section) => {
          const allow = permissions.find((x) => x === section.permission);
          if (allow)
            return (
              <BottomNavigationAction
                key={section.id}
                label={section.title}
                value={section.path}
                icon={section.icon}
              />
            );
          else return null;
        })}
      </BottomNavigation>
      <BottomNavigation
        showLabels
        sx={{ display: { xs: 'flex', md: 'none' } }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(newValue);
        }}
      >
        <BottomNavigationAction value={'/'} label="Inicio" icon={<DashboardIcon />} />
        {permissions.find((x) => x === 17) ? (
          <BottomNavigationAction
            onClick={handlePopoverOpen('inventory')}
            label="Inventario"
            icon={<InventoryIcon />}
          />
        ) : null}
        <BottomNavigationAction
          onClick={handlePopoverOpen('sections')}
          label="Más opciones"
          icon={<MoreHorizIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
