import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import { IconButton } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import $ from 'jquery';
import mainURL from '../../config/environment';

const emptyModel = {
  id: '',
  name: '',
};

const useEquipments = (refresh, status) => {
  const [equipments, setEquipments] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    const statusParam = status ? `?status=${status}` : '';
    $.ajax({
      method: 'GET',
      url: `${mainURL}equipment/get-all${statusParam}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) setEquipments(res);
    });
    return () => (isSubscribed = false);
  }, [refresh, status]);
  return equipments;
};

export default function SearchAndSelectEquipmentInput(props) {
  const { refresh, usedEquipments, status, autoSelect } = props;

  const equipments = useEquipments(refresh, status);

  const [model, setModel] = useState(emptyModel);
  const [selectedReference, setSelectedReference] = useState(null);

  const handleChange = (newValue) => {
    if (newValue !== null) {
      setSelectedReference(newValue);
      setModel({
        ...model,
        id: newValue.id,
        name: `${newValue.name} #${newValue.identifier}`,
      });
      if (autoSelect)
        props.handleAdd({
          ...newValue,
          id: newValue.id,
          name: `${newValue.name} #${newValue.identifier}`,
        });
    } else {
      setModel(emptyModel);
      setSelectedReference(null);
    }
  };

  const handleSubmit = () => {
    props.handleAdd(model);
    setModel(emptyModel);
    setSelectedReference(null);
  };

  return (
    <Grid container alignItems={'center'} spacing={2}>
      {autoSelect ? null : (
        <Grid item sm={12}>
          <Typography variant="h6">Equipos</Typography>
        </Grid>
      )}
      <Grid item md={autoSelect ? 12 : 10}>
        <FormControl required fullWidth>
          <Autocomplete
            id="tags-standard"
            options={equipments.filter((x) => !usedEquipments.includes(x.id))}
            getOptionLabel={(opt) => `${opt.name} #${opt.identifier}`}
            value={selectedReference}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label="Equipo" />}
          />
        </FormControl>
      </Grid>
      {autoSelect ? null : (
        <Grid item md={2} container justifyContent={'center'}>
          <IconButton onClick={handleSubmit}>
            <Tooltip title="Agregar equipo">
              <CheckCircle color={model === emptyModel ? 'action' : 'primary'} />
            </Tooltip>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
