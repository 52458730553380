import React, { useState, useEffect } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import ConstructionIcon from '@mui/icons-material/Construction';

import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import UpdateEquipmentDialog from '../dialogs/updateEquipment';
import DeleteEquipmentDialog from '../dialogs/deleteGeneric';
import FullSizeImage from '../dialogs/fullSizeImage';

import mainURL from '../../config/environment';
import $ from 'jquery';
import CreateTicketDialog from '../dialogs/createTicket';
import { useNavigate } from 'react-router-dom';
import ChangeImagenDialog from '../dialogs/changeImageDialog';

const emptyData = {
  id: '',
  name: '',
  base64Image: '',
  reference: '',
  identifier: 0,
  status: '',
  boughtOn: '',
  commercialPrice: 0,
  equipmentCategoryId: '',
  constructionId: '',
  constructionName: '',
  categoryName: '',
};

const errorMessage =
  'No se puede borrar este equipo porque hay alquileres o mantenimientos registrados a su nombre';

export default function EquipmentDetailsCard(props) {
  const [data, setData] = useState(emptyData);

  const navigate = useNavigate();
  const { equipmentId } = props;
  const { setRefresh } = props;
  const { refresh } = props;

  //Dialog management
  const [maintenanceDialog, setMaintenanceDialog] = useState(false);
  const [changeImageDialog, setChangeImageDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);

  const handleCloseDialogs = () => {
    setChangeImageDialog(false);
    setMaintenanceDialog(false);
    setDeleteDialog(false);
    setUpdateDialog(false);
    setImageDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'image':
        setImageDialog(true);
        break;

      case 'change-image':
        setChangeImageDialog(true);
        break;

      case 'rent':
        navigate('/remisiones/crear');
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'rename':
        setUpdateDialog(true);
        break;

      case 'maintenance':
        setMaintenanceDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Cargando equipos");
    $.ajax({
      method: 'GET',
      url: mainURL + `equipment/${equipmentId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res;
        if (isSubscribed) {
          setData(aux);
          //handleShowNotification("success", "Equipos cargados con éxito");
        }
      })
      .fail((res) => {
        //handleShowNotification("error", res.responseText);
      });

    return () => (isSubscribed = false);
  }, [equipmentId, refresh]);

  return (
    <React.Fragment>
      <CreateTicketDialog
        refresh={refresh}
        open={maintenanceDialog}
        setRefresh={setRefresh}
        name={data.name}
        equipmentId={data.id}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      {/* <RentEquipmentDialog
        name={data.name}
        open={rentDialog}
        refresh={refresh}
        equipmentId={data.id}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      /> */}
      <DeleteEquipmentDialog
        name={data.name}
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        title={'Eliminar equipo'}
        errorMessage={errorMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`equipment/${data.id}`}
        handleShowNotification={handleShowNotification}
      />
      <FullSizeImage
        open={imageDialog}
        name={data.name}
        image={data.base64Image}
        handleClose={handleCloseDialogs}
      />
      <UpdateEquipmentDialog
        refresh={refresh}
        open={updateDialog}
        setRefresh={setRefresh}
        equipmentId={equipmentId}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <ChangeImagenDialog
        refresh={refresh}
        open={changeImageDialog}
        setRefresh={setRefresh}
        equipmentId={equipmentId}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Card>
        <CardHeader
          avatar={
            <IconButton size="small" onClick={handleOpenDialog('image')}>
              <Avatar src={data.base64Image} sx={{ height: '100px', width: '100px' }} />
            </IconButton>
          }
          title={<Typography variant="h5">{data.name}</Typography>}
          subheader={
            <Typography variant="subtitle1">{`${data.reference} - ${data.identifier}`}</Typography>
          }
          action={
            showNotification ? (
              <Alert variant="outlined" severity={notificationMessage.severity}>
                {notificationMessage.message}
              </Alert>
            ) : (
              <Grid container>
                <IconButton
                  color="primary"
                  disabled={data.status === 'Alquilado'}
                  onClick={handleOpenDialog('rent')}
                >
                  <Tooltip title="Alquilar">
                    <EventIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton
                  color="primary"
                  disabled={data.status === 'Alquilado'}
                  onClick={handleOpenDialog('maintenance')}
                >
                  <Tooltip title="Abrir mantenimiento">
                    <ConstructionIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton color="primary" onClick={handleOpenDialog('rename')}>
                  <Tooltip title="Editar">
                    <EditIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton color="primary" onClick={handleOpenDialog('delete')}>
                  <Tooltip title="Eliminar">
                    <DeleteIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton color="primary" onClick={handleOpenDialog('change-image')}>
                  <Tooltip title="Cambiar imagen">
                    <AddPhotoAlternateIcon fontSize="large" />
                  </Tooltip>
                </IconButton>
              </Grid>
            )
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Categoría'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.categoryName}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Estado'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.status}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Ubicación'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.constructionName}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Precio comercial'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {numberWithCommas(data.commercialPrice)}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Comprado en'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {new Date(data.boughtOn).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
