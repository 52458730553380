import React, { useState } from 'react';

//MUI
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Link } from 'react-router-dom';

//Selfmade
import Copyright from './copyright';

//React router dom
import { useNavigate } from 'react-router-dom';

import mainURL from '../../config/environment';
import $ from 'jquery';

export default function Login() {
  const navigate = useNavigate();
  //Load management
  const [isLoading, setLoading] = useState(false);

  //Data management
  const [model, setModel] = useState({
    email: '',
    password: '',
  });

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    $.ajax({
      method: 'POST',
      url: mainURL + 'login/authenticate-user',
      contentType: 'application/json',
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        localStorage.setItem('userInfo', JSON.stringify(res));
        navigate('/');
      })
      .fail((res) => {
        setLoading(false);
        if (res.status === 401) {
          handleShowNotification('warning', 'Contraseña o email inválidos');
        } else if (res.status === 500) {
          handleShowNotification('warning', 'Algo anda mal');
        }
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img src="assets/images/Logo.png" width="100%" alt="Logo" />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5, width: '100%' }}>
          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />

          {isLoading ? (
            <Grid container justifyContent={'center'} sx={{ mt: 3, mb: 2 }}>
              <CircularProgress />
            </Grid>
          ) : showNotification ? (
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          ) : (
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Iniciar sesión
            </Button>
          )}
          {!isLoading ? (
            <Grid container justifyContent={'center'}>
              <Grid item>
                <Link to="/forgot-password" variant="body2">
                  ¿Olvidó su contraseña?
                </Link>
              </Grid>
            </Grid>
          ) : null}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Container>
  );
}
