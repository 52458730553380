import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import $ from 'jquery';
import mainURL from '../../config/environment';

import { alquicomClientId } from '../../config/appConstants';

const useClients = () => {
  const [clients, setClients] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + 'client/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      const aux = res.filter((m) => m.id !== alquicomClientId);
      if (isSubscribed) setClients(aux);
    });
    return () => (isSubscribed = false);
  }, []);
  return clients;
};

export default function SelectClientAndConstruction(props) {
  const { handleChange } = props;
  const { required } = props;
  const { value } = props;
  const { name } = props;

  const [client, setClient] = useState(null);
  const clients = useClients();

  const handleClientChange = (newValue) => {
    if (newValue !== null) setClient(newValue);
    else setClient(null);
  };

  const handleConstructionChange = (newValue) => {
    handleChange(newValue);
  };

  const [constructions, setConstructions] = useState([]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    if (client !== null) {
      $.ajax({
        method: 'GET',
        url: `${mainURL}construction/get-all?clientId=${client.id}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
          hostname: host,
        },
      }).done((res) => {
        if (isSubscribed) {
          setConstructions(res);
        }
      });
    }
    return () => (isSubscribed = false);
  }, [client]);

  return (
    <React.Fragment>
      <Grid item sm={6}>
        <FormControl fullWidth required={required}>
          <Autocomplete
            id="tags-standard"
            options={clients}
            getOptionLabel={(opt) => opt.name}
            value={client}
            onChange={(event, newValue) => {
              handleClientChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label="Cliente" />}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <FormControl fullWidth required={required}>
          <Autocomplete
            id="tags-standard"
            options={constructions}
            getOptionLabel={(opt) => opt.name}
            value={constructions.filter((m) => m[name] === value)[0]}
            disabled={client === null}
            onChange={(event, newValue) => {
              handleConstructionChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label="Obra" />}
          />
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
