import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//MUI-LAB
import $ from 'jquery';
import mainURL from '../../config/environment';

const emptyModel = {
  name: '',
  reference: '',
  base64Image: '',
  boughtOn: new Date(Date.now()),
  commercialPrice: 0,
  equipmentCategoryId: '',
  identifier: 0,
};

export default function ChangeImagenDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const { refresh, equipmentId } = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;

    $.ajax({
      method: 'PUT',
      url: mainURL + `equipment/${equipmentId}/change-image`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Imagen actualizada con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', 'La imagen supera el tamaño permitido');
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    reader.onload = (e) => {
      let image = e.target.result;
      setModel({ ...model, base64Image: image });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Actualizar imagen'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControl fullWidth required>
                <InputLabel variant="standard">{'Imagen'}</InputLabel>
                <Input type="file" onChange={handleFileChange} />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              Actualizar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
