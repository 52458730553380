import React, { useState, useEffect, useMemo } from 'react';

//Material UI
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';

import { useWidth } from '../../utils/widthSelector';

//JQuery
import mainURL from '../../config/environment';
import $ from 'jquery';

export default function RenameGenericDialog(props) {
  const { renameURL } = props;
  const { refresh } = props;
  const { title } = props;
  const { name } = props;

  const [model, setModel] = useState({ name: name });

  const handleChange = (event) => {
    const value = event.target.value;
    setModel({ name: value });
  };

  const breakpoint = useWidth();

  const minWidth = useMemo(() => {
    if (breakpoint === 'xs') return '300px';
    else return '600px';
  }, [breakpoint]);

  //Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    $.ajax({
      method: 'PUT',
      url: mainURL + renameURL,
      contentType: 'application/json',
      data: JSON.stringify(model),
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        handleClear();
        props.handleShowNotification('success', 'Renombrado con éxito');
      })
      .fail((res) => {
        handleClear();
        props.handleShowNotification('error', res.responseText);
      });
  };

  const handleClear = () => {
    props.setRefresh(!refresh);
    props.handleClose();
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setModel({ name: name });
    }
    return () => (isSubscribed = false);
  }, [name, refresh]);

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ minWidth: minWidth }}>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <FormControl fullWidth>
            <TextField
              onChange={handleChange}
              value={model.name}
              variant="standard"
              label="Nombre"
              margin="dense"
              name="name"
              type="text"
              autoFocus
              required
              sx={{ minWidth: '100px' }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          {'Cancelar'}
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          {'Aceptar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
