import React, { useState, useEffect } from 'react';

//MUI
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';

import { useNavigate, useParams } from 'react-router-dom';

//Icons

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';

import CreateEquipmentDialog from '../../components/dialogs/createEquipment';
import RenameEquipmentDialog from '../../components/dialogs/renameGeneric';
import DeleteEquipmentDialog from '../../components/dialogs/deleteGeneric';
import AdvancedSearchDialog from '../../components/dialogs/advancedSearch';
import CreateTicketDialog from '../../components/dialogs/createTicket';
import SearchAndCreate from '../../components/input/searchAndCreate';
import OptionsPopover from '../../components/popovers/generic';

import { useWidth } from '../../utils/widthSelector';
import mainURL from '../../config/environment';
import $ from 'jquery';

const emptyData = {
  id: '',
  name: '',
  reference: '',
  status: '',
  boughtOn: '',
  commercialPrice: 0,
  equipmentCategoryId: '',
  constructionId: '',
  constructionName: '',
  categoryName: '',
};

const errorMessage =
  'No se puede borrar este equipo porque hay alquileres o mantenimientos registrados a su nombre';

const options = [
  {
    buttonLabelTooltip: 'Descargar',
    action: 'export',
    icon: <DownloadIcon />,
    buttonLabel: 'Crear',
    showButtonLabel: false,
  },
];

export default function Equipments(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  const { categoryId, title, showCreateOption } = props;

  const { constructionId } = useParams();

  const breakpoint = useWidth();

  const navigate = useNavigate();

  const columns = [
    {
      headerName: 'Nombre',
      field: 'name',
      flex: 2,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Referencia',
      field: 'reference',
      flex: 1,
      breakpoints: ['sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Identificador',
      field: 'identifier',
      //flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Estado',
      field: 'status',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    /* {
      headerName: "Fecha de compra",
      flex: 1,
      field: "boughtOn",
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {params.row.boughtOn.split("T")[0]}
        </Typography>
      ),
    }, */
    {
      headerName: 'Ubicación',
      field: 'constructionName',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    {
      headerName: 'Opciones',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      //flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  //Dialog management
  const [advancedSearchDialog, setAdvancedSearchDialog] = useState(false);
  const [maintenanceDialog, setMaintenanceDialog] = useState(false);

  const [renameDialog, setRenameDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);

  const handleCloseDialogs = () => {
    setAdvancedSearchDialog(false);
    setMaintenanceDialog(false);
    setRenameDialog(false);
    setDeleteDialog(false);
    setCreateDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        setCreateDialog(true);
        break;

      case 'export':
        handleExport();
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'rename':
        setRenameDialog(true);
        break;

      case 'search':
        setAdvancedSearchDialog(true);
        break;

      case 'refresh':
        setSearchText('');
        setRefresh(!refresh);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  const handleExport = () => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;

    $.ajax({
      method: 'GET',
      url: `${mainURL}equipment/export?categoryId=${categoryId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
      xhr: function () {
        // Seems like the only way to get access to the xhr object
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        return xhr;
      },
    })
      .done((res) => {
        downloadFile(res);
      })
      .fail((res) => {
        console.log(res);
      });
  };

  const downloadFile = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const fileName = `Inventario de equipos.xlsx`;
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => {
    if (data.row !== selectedData) navigate(`/equipos/${data.id}`); //setSelectedData(data.row);
    else setSelectedData(emptyData);
  };

  const showDetails = () => {
    navigate(`/equipos/${selectedData.id}`);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    //let url = ;
    //if (constructionId) url = `${mainURL}construction/${constructionId}/equipments`;
    $.ajax({
      method: 'GET',
      url: `${mainURL}equipment/get-all?categoryId=${categoryId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res;
        if (isSubscribed) {
          setData(aux);
          setFilteredData(aux);
          //handleShowNotification("success", "Equipos cargados con éxito");
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh, categoryId, constructionId]);

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => `${f.name} ${f.reference}`.toLowerCase().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <OptionsPopover
        open={anchor}
        showRenameOption
        showDeleteOption
        showDetailsOption
        showCreateTicketOption
        showDetails={showDetails}
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
        setRenameDialog={setRenameDialog}
        setMaintenanceDialog={setMaintenanceDialog}
      />
      <CreateTicketDialog
        refresh={refresh}
        open={maintenanceDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        equipmentId={selectedData.id}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <DeleteEquipmentDialog
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        title={'Eliminar equipo'}
        errorMessage={errorMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`equipment/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      />
      <RenameEquipmentDialog
        refresh={refresh}
        open={renameDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        title={'Renombrar equipo'}
        handleClose={handleCloseDialogs}
        renameURL={`equipment/${selectedData.id}/rename`}
        handleShowNotification={handleShowNotification}
      />
      <CreateEquipmentDialog
        title={title.toLowerCase()}
        refresh={refresh}
        open={createDialog}
        setRefresh={setRefresh}
        categoryId={categoryId}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <AdvancedSearchDialog
        refresh={refresh}
        open={advancedSearchDialog}
        setRefresh={setRefresh}
        setFilteredData={setFilteredData}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />

      <Grid
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: '1rem 0' }}
        spacing={2}
        container
      >
        <Grid item md={8}>
          <Typography variant="h4">{title}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : showCreateOption ? (
          <SearchAndCreate
            showAdvanceSearchIcon={data.length === filteredData.length}
            title={'Buscar por nombre o referencia'}
            handleOpenDialog={handleOpenDialog}
            handleSearch={handleSearch}
            searchText={searchText}
            options={options}
            showExportOption
            showRefreshIcon
            permission={18}
          />
        ) : null}
      </Grid>

      <Grid container height={'70vh'} sx={{ pb: 5 }}>
        <DataGrid
          selectionModel={selectedData.id === '' ? [] : selectedData.id}
          onRowClick={handleSelect}
          rows={filteredData}
          columns={columns.filter((m) => m.breakpoints.includes(breakpoint))}
          disableColumnMenu
        />
      </Grid>
    </Box>
  );
}
