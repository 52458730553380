import React, { useContext } from 'react';

//React router
import { useNavigate } from 'react-router-dom';

//MUI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';

import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import EscalatorIcon from '@mui/icons-material/Escalator';
import SettingsIcon from '@mui/icons-material/Settings';

import { PermissionContext } from '../../config/permissionContext';

const sections = [
  {
    id: 'machinery',
    title: 'Maquinaria',
    icon: <AgricultureIcon />,
    path: '/maquinaria',
    permission: 17,
  },
  {
    id: 'equipments',
    title: 'Equipos',
    icon: <PrecisionManufacturingIcon />,
    path: '/equipos',
    permission: 17,
  },
  {
    id: 'supplies',
    title: 'Repuestos',
    icon: <SettingsIcon />,
    path: '/repuestos',
    permission: 21,
  },
  {
    id: 'scaffolds',
    title: 'Andamios',
    icon: <EscalatorIcon />,
    path: '/andamios',
    permission: 21,
  },
  {
    id: 'adjusments',
    title: 'Movimientos',
    icon: <DisplaySettingsIcon />,
    path: '/movimientos-inventario',
    permission: 21,
  },
];

export default function SectionsPopover(props) {
  const permissions = useContext(PermissionContext);
  const navigate = useNavigate();
  const { open } = props;

  const handleSelect = (path) => (event) => {
    navigate(path);
    props.handleClose();
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={Boolean(open)}
      anchorEl={open}
      onClose={props.handleClose}
    >
      <List dense>
        {sections.map((section) => {
          const allow = permissions.find((x) => x === section.permission);
          if (allow) {
            return (
              <ListItemButton key={section.id} onClick={handleSelect(section.path)}>
                <ListItemIcon>{section.icon}</ListItemIcon>
                <ListItemText primary={section.title} />
              </ListItemButton>
            );
          } else return null;
        })}
      </List>
    </Popover>
  );
}
