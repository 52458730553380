import React, { useState, useEffect } from 'react';

//MUI
import IconButton from '@mui/material/IconButton';

import { DataGrid } from '@mui/x-data-grid';

import { useNavigate, useParams } from 'react-router-dom';

//Icons
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import mainURL from '../../config/environment';
import $ from 'jquery';
import { useWidth } from '../../utils/widthSelector';

export default function EquipmentsModule(props) {
  //Data management

  //const [filteredData, setFilteredData] = useState([]);

  const [data, setData] = useState([]);

  //const { categoryId, title, showCreateOption } = props;

  const { constructionId, clientId } = useParams();

  const breakpoint = useWidth();

  const navigate = useNavigate();

  const columns = [
    {
      headerName: 'Nombre',
      field: 'name',
      flex: 2,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Referencia',
      field: 'reference',
      flex: 1,
      breakpoints: ['sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Identificador',
      field: 'identifier',
      //flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Estado',
      field: 'status',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    /* {
      headerName: "Fecha de compra",
      flex: 1,
      field: "boughtOn",
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {params.row.boughtOn.split("T")[0]}
        </Typography>
      ),
    }, */
    {
      headerName: 'Ubicación',
      field: 'constructionName',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    {
      headerName: 'Detalles',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={showDetails(params.row.id)}>
          <ExitToAppIcon />
        </IconButton>
      ),
      //flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  /*  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  }; */

  //Notification management

  const showDetails = (id) => () => {
    navigate(`/equipos/${id}`);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;

    const clientParam = clientId ? `clientId=${clientId}` : '';
    const constructionParam = constructionId ? `&constructionId=${constructionId}` : '';

    $.ajax({
      method: 'GET',
      url: `${mainURL}equipment/get-all?${clientParam}${constructionParam}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res;
        if (isSubscribed) {
          setData(aux);
          //setFilteredData(aux);
        }
      })
      .fail((res) => {
        console.log(res);
      });
    return () => (isSubscribed = false);
  }, [clientId, constructionId]);

  /* useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => `${f.name} ${f.reference}`.toLowerCase().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]); */

  return (
    <DataGrid
      //selectionModel={selectedData.id === '' ? [] : selectedData.id}
      //onRowClick={handleSelect}
      rows={data}
      columns={columns.filter((m) => m.breakpoints.includes(breakpoint))}
      hideFooter
      autoHeight
      disableColumnMenu
    />
  );
}
