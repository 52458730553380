import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import $ from 'jquery';
import mainURL from '../../config/environment';

const useUsers = (refresh) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + 'user/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).done((res) => {
      const aux = res.filter((m) => m.id > 0);
      if (isSubscribed) setUsers(aux);
    });
    return () => (isSubscribed = false);
  }, []);
  return users;
};

export default function SelectUser(props) {
  const { handleChange } = props;
  const { required } = props;
  const { refresh } = props;
  const { value } = props;
  const { name } = props;

  const users = useUsers(refresh);

  return (
    <FormControl fullWidth required={required}>
      <InputLabel variant="standard">{'Usuario'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value="" />
        {users.map((usr) => (
          <option key={usr.id} value={usr.id}>
            {usr.fullName}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
