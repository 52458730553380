import React, { useState, useEffect } from 'react';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import { useParams } from 'react-router-dom';

import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import mainURL from '../../config/environment';
import $ from 'jquery';

const emptyModel = { totalPrice: 0 };

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Times-Roman',
  },
  headers: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
  },
  date: {
    fontSize: 14,
  },
  section: {
    paddingVertical: 10,
    flexDirection: 'column',
  },
  diagnostic: {
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  technician: {
    fontSize: 14,
    color: '#121212',
  },
  image: {
    width: '200px',
    maxHeight: '400px',
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default function MaintenanceReport() {
  const [model, setModel] = useState(emptyModel);

  //Navigation
  const { maintenanceId } = useParams();

  const [editableData, setEditableData] = useState([]);
  const [price, setPrice] = useState('');
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleChangeDiagnostic = (index) => (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    const diagnostics = [...editableData];
    //console.log(name, value);
    diagnostics.splice(index, 1, { ...data[index], [name]: value });

    setEditableData(diagnostics);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPrice(numberWithCommas(model.totalPrice));
    setData(editableData);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;

    $.ajax({
      method: 'GET',
      url: mainURL + `maintenance-daily-control/${maintenanceId}/get-all-with-images`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setData(res);
          setEditableData(res);
        }
      })
      .fail((res) => {});
    return () => (isSubscribed = false);
  }, [maintenanceId]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + `maintenance-ticket/${maintenanceId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          console.log(res);
          setTitle(`${res.equipmentName} - ${res.constructionName}`);
        }
      })
      .fail((res) => {});
    return () => (isSubscribed = false);
  }, [maintenanceId]);

  return (
    <Grid container spacing={2} height={'90%'}>
      <Grid item sm={6}>
        <Card>
          <CardContent>
            {editableData.map((entry, idx) => (
              <FormControl fullWidth required key={entry.id}>
                <TextField
                  label={`Diagnóstico - ${entry.date.split('T')[0]}`}
                  onChange={handleChangeDiagnostic(idx)}
                  value={entry.diagnostic}
                  variant="standard"
                  name="diagnostic"
                  margin="dense"
                  type="text"
                  fullWidth
                />
              </FormControl>
            ))}
            <FormControl fullWidth required>
              <TextField
                label={'Precio total'}
                onChange={handleChange}
                value={model.totalPrice}
                variant="standard"
                name="totalPrice"
                margin="dense"
                type="number"
                fullWidth
                required
              />
            </FormControl>
          </CardContent>
          <CardActions>
            <Grid container justifyContent={'flex-end'}>
              <Button type="submit" variant="contained" onClick={handleSubmit}>
                Refrescar
              </Button>
            </Grid>
          </CardActions>
        </Card>
      </Grid>

      <Grid item sm={6}>
        <Card sx={{ height: '100%' }}>
          <CardContent sx={{ height: '100%' }}>
            <PDFViewer width={'100%'} height={'100%'}>
              <Document>
                <Page size="A4" style={styles.body}>
                  <View style={styles.headers}>
                    <Text style={styles.title}>{title}</Text>
                    <Text style={styles.date}>
                      {new Date(Date.now()).toISOString().split('T')[0]}
                    </Text>
                  </View>

                  {data.map((entry) => (
                    <View key={entry.id} style={styles.section}>
                      <Text style={styles.diagnostic}>{entry.diagnostic}</Text>
                      <View style={styles.images}>
                        {entry.images.map((img) => (
                          <Image key={img.id} src={img.base64Image} style={styles.image} />
                        ))}
                      </View>
                    </View>
                  ))}

                  <Text style={styles.title}>{'Precio total'}</Text>
                  <Text style={styles.title}>{price}</Text>
                </Page>
              </Document>
            </PDFViewer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
