import React, { useEffect, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
//import Alert from "@mui/material/Alert";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import EscalatorIcon from '@mui/icons-material/Escalator';
import CancelIcon from '@mui/icons-material/Cancel';

//MUI-LAB
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

import $ from 'jquery';
import mainURL from '../../config/environment';

import SelectClientAndConstruction from '../../components/selects/selectClientAndConstruction';
import SearchAndSelectEquipmentInput from '../../components/input/searchAndSelectEquipment';
import ScaffoldSurvey from '../../components/input/scaffoldSurvey';
import { useNavigate } from 'react-router-dom';

const emptyModel = {
  number: '',
  constructionId: '',
  notes: '',
  equipments: [],
  scaffoldCategories: [],
  createdOn: new Date(),
};

const useConstructions = () => {
  const [constructions, setConstructions] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}construction/get-all`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) {
        setConstructions(res);
      }
    });
    return () => (isSubscribed = false);
  }, []);
  return constructions;
};

export default function CreateRemission(props) {
  //const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const navigate = useNavigate();

  const constructions = useConstructions();

  const constructionName = useMemo(
    () => constructions.filter((x) => x.id === model.constructionId)[0]?.name,
    [model.constructionId, constructions],
  );

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (name === 'constructionId') {
      setModel({
        ...model,
        [name]: value,
        constructionName: target.innerText,
      });
    } else {
      setModel({
        ...model,
        [name]: value,
        //constructionName: target.innerText,
      });
    }
  };

  const handleConstructionChange = (constr) => {
    setModel({ ...model, constructionId: constr.id, constructionName: constr.name });
  };

  const handleAddEquipment = (item) => {
    const a = [...model.equipments];

    a.push(item);
    setModel({ ...model, equipments: a });
  };

  const handleAddScaffoldCategory = (item) => {
    const a = [...model.scaffoldCategories];

    a.push(item);
    setModel({ ...model, scaffoldCategories: a });

    console.log(a);
  };

  const handleDeleteEquipment = (index) => () => {
    console.log(index);
    let a = [...model.equipments];
    a.splice(index, 1);

    setModel({ ...model, equipments: a });
  };

  const handleDeleteScaffold = (index, index2) => () => {
    let a = [...model.scaffoldCategories];
    console.log(a);
    let b = a[index];
    console.log(b);
    let c = [...b.scaffolds];
    console.log(c);
    c.splice(index2, 1);
    console.log(c);
    b.scaffolds = c;
    console.log(b);
    a[index] = b;
    console.log(a);

    setModel({ ...model, scaffoldCategories: a });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;

    const equipmentIds = model.equipments.map((m) => m.id);

    const submitModel = {
      ...model,
      equipments: equipmentIds,
      createdOn: new Date(model.createdOn).toISOString(),
    };

    $.ajax({
      method: 'POST',
      url: `${mainURL}remission`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(submitModel),
    })
      .done((res) => {
        setLoading(false);
        navigate('/remisiones');
        handleClear();
      })
      .fail((res) => {
        setLoading(false);

        //handleShowNotification("error", res.responseText);
        //handleClear();
      });
  };

  const submittable = useMemo(() => model.number !== '' && model.constructionId !== '', [model]);

  // //Notification management
  // const [showNotification, setShowNotification] = useState(false);
  // const [notificationMessage, setNotificationMessage] = useState({
  //   severity: "",
  //   message: "",
  // });

  // const handleShowNotification = (severity, message) => {
  //   setNotificationMessage({ severity: severity, message: message });
  //   setShowNotification(true);
  //   setTimeout(function () {
  //     setShowNotification(false);
  //   }, 2000);
  // };

  const handleDateChange = (event) => {
    //const date = new Date(event).toISOString();
    setModel({
      ...model,
      createdOn: event,
    });
  };

  const handleClear = () => {
    setModel(emptyModel);
  };

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <Grid container spacing={2} sx={{ pb: 10 }}>
        <Grid item container md={6}>
          <Card sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <CardContent>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={6}>
                  <FormControl fullWidth required>
                    <TextField
                      label={'Número'}
                      onChange={handleChange}
                      value={model.number}
                      variant="standard"
                      name="number"
                      margin="dense"
                      type="number"
                      step="1"
                      fullWidth
                      autoFocus
                      required
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={'Fecha'}
                        value={model.createdOn}
                        format="dd/MM/yyyy"
                        onChange={handleDateChange}
                        textField={(params) => <TextField variant="standard" {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <SelectClientAndConstruction
                  handleChange={handleConstructionChange}
                  value={model.constructionId}
                  refresh={props.open}
                  name="constructionId"
                />

                <Grid
                  item
                  sm={12}
                  sx={{
                    border: '1px gray dashed',
                    borderRadius: '5px',
                    m: '16px 0 0 8px',
                    p: '8px 16px',
                  }}
                >
                  <SearchAndSelectEquipmentInput
                    handleAdd={handleAddEquipment}
                    usedEquipments={model.equipments.map((m) => m.id)}
                    status={0}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  sx={{
                    border: '1px gray dashed',
                    borderRadius: '5px',
                    m: '16px 0 0 8px',
                    p: '8px 16px',
                  }}
                >
                  <ScaffoldSurvey handleAdd={handleAddScaffoldCategory} />
                </Grid>
                <Grid item sm={12}>
                  <FormControl fullWidth required>
                    <TextField
                      onChange={handleChange}
                      value={model.notes}
                      variant="outlined"
                      label="Comentarios adicionales"
                      name="notes"
                      margin="dense"
                      type="text"
                      multiline
                      autoFocus
                      rows={5}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {model !== emptyModel ? (
          <Grid item container md={6}>
            <Card sx={{ width: '100%' }}>
              <CardHeader title={model.number} subheader={constructionName} />
              <CardContent>
                <Divider />
                <List>
                  {model.equipments.map((equipment, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={handleDeleteEquipment(index)}
                        >
                          <CancelIcon />
                        </IconButton>
                      }
                    >
                      <ListItemIcon>
                        <PrecisionManufacturingIcon />
                      </ListItemIcon>
                      <ListItemText primary={equipment.name} />
                    </ListItem>
                  ))}
                </List>

                <Grid container>
                  {model.scaffoldCategories.map((category, index) => (
                    <React.Fragment>
                      <Grid item sm={12} key={index}>
                        <Typography>{category.scaffoldCategoryName}</Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <List>
                          {category.scaffolds.map((scaffold, index2) => (
                            <ListItem
                              key={index2}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={handleDeleteScaffold(index, index2)}
                                >
                                  <CancelIcon />
                                </IconButton>
                              }
                            >
                              <ListItemIcon>
                                <EscalatorIcon />
                              </ListItemIcon>
                              <ListItemText primary={scaffold.name} secondary={scaffold.quantity} />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'flex-end'}>
                  {!isLoading ? (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!submittable}
                      onClick={handleSubmit}
                    >
                      Crear
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}

/* <ListItem
                    
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={handleDeleteScaffold(index)}
                      >
                        <CancelIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <EscalatorIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={scaffold.name}
                      secondary={scaffold.quantity}
                    />
                  </ListItem> */
