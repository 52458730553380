import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Grid from '@mui/material/Grid';
import { useTheme } from '@emotion/react';

export default function ClientCard(props) {
  const { client, handlePopoverOpen, handleSelect } = props;

  const theme = useTheme();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        onClick={handleSelect(client)}
        sx={{ cursor: 'pointer', ':hover': { backgroundColor: theme.palette.divider } }}
      >
        <CardHeader
          avatar={<Avatar aria-label="recipe">{client.name.charAt(0)}</Avatar>}
          action={
            <IconButton aria-label="settings" onClick={handlePopoverOpen(client)}>
              <MoreVertIcon />
            </IconButton>
          }
          title={client.name}
          subheader={client.email}
        />
      </Card>
    </Grid>
  );
}
