import React, { useState } from 'react';

import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import $ from 'jquery';
import mainURL from '../../config/environment';

import SelectConstruction from '../selects/selectConstruction';
import SelectMaintenanceType from '../selects/selectMaintenanceType';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import SearchAndSelectEquipmentInput from '../input/searchAndSelectEquipment';
import { alquicomConstructionId } from '../../config/appConstants';

const emptyModel = {
  equipmentId: '',
  maintenanceType: 0,
  remission: '',
  constructionId: '',
  startDate: new Date(),
};

export default function CreateTicketDialog(props) {
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const { equipmentId } = props;
  const { refresh } = props;
  const { name } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (equipmentId !== null) {
      setModel({
        ...model,
        [name]: value,
        equipmentId: equipmentId,
      });
    } else {
      setModel({
        ...model,
        [name]: value,
      });
    }
    if (model !== emptyModel) {
      setFormComplete(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const auxModel = {
      ...model,
      startDate: new Date(model.startDate).toISOString(),
      constructionId: model.constructionId === '' ? alquicomConstructionId : model.constructionId,
    };
    console.log(auxModel);
    $.ajax({
      method: 'POST',
      url: mainURL + 'maintenance-ticket',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(auxModel),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Mantenimiento creado con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  const handleAddEquipment = (item) => {
    console.log(item);
    setModel({ ...model, equipmentId: item.id, constructionId: item.constructionId });
  };

  const handleDateChange = (event) => {
    //const date = new Date(event).toISOString();
    setModel({ ...model, startDate: event });
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Crear tiquete de mantenimiento'}</DialogTitle>
      <DialogContent>
        {equipmentId === null ? null : (
          <DialogContentText mb={2}>{`Crear ticket para ${name}`}</DialogContentText>
        )}
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {equipmentId === null ? (
              <Grid item sm={12}>
                <SearchAndSelectEquipmentInput
                  handleAdd={handleAddEquipment}
                  usedEquipments={[]}
                  status={null}
                  autoSelect
                />
              </Grid>
            ) : null}
            <Grid item sm={12}>
              <SelectConstruction
                title="Obra de la que procede"
                name={'constructionId'}
                handleChange={handleChange}
                value={model.constructionId}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth required>
                <TextField
                  label={'Número de remisión'}
                  onChange={handleChange}
                  value={model.remission}
                  variant="standard"
                  name="remission"
                  margin="dense"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <SelectMaintenanceType
                name={'maintenanceType'}
                handleChange={handleChange}
                value={model.maintenanceType}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Fecha'}
                    value={model.startDate}
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    textField={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" onClick={handleSubmit} disabled={!isFormComplete}>
              Crear
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
