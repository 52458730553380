import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ConstructionIcon from '@mui/icons-material/Construction';
import EventIcon from '@mui/icons-material/Event';

import LineChart from './charts/lineChart';
import PieChart from './charts/pieChart';
import ReportCard from './cards/report';

export default function Dashboard() {
  return (
    <Grid container p={3} spacing={2}>
      <Grid item sm={12} m={2}>
        <Typography variant="h5" color="textSecondary" sx={{ fontWeight: 800 }}>
          {'Alquiler de equipos de construcción y mantenimiento '}
        </Typography>
      </Grid>

      {[
        {
          id: 'client',
          icon: EventIcon,
          text: 'Generar reporte de alquiler',
          endpoint: 'rentals',
        },
        {
          id: 'equipment',
          icon: ConstructionIcon,
          text: 'Generar reporte de mantenimientos',
          endpoint: 'maintenance',
        },
      ].map((card) => (
        <Grid key={card.id} item xs={12} md={6}>
          <ReportCard Icon={card.icon} text={card.text} endpoint={card.endpoint} />
        </Grid>
      ))}
      <Grid item xs={12} md={8}>
        <LineChart
          endpoint={'dashboard/rentals-over-time'}
          title={'Número de alquiléres en los últimos meses'}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <PieChart
          endpoint={'dashboard/equipment-distribution'}
          title={'Equipos actualmente alquilados'}
        />
      </Grid>
    </Grid>
  );
}
