import React, { useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import $ from 'jquery';
import mainURL from '../../config/environment';
import SelectScaffoldCategory from '../selects/selectScaffoldCategory';

const emptyModel = {
  name: '',
  reference: '',
  base64Image: '',
  scaffoldCategoryId: '',
  totalQuantity: 0,
  rentedQuantity: 0,
  commercialPrice: 0,
};

export default function CreateScaffoldDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const { refresh, categoryId } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
      equipmentCategoryId: categoryId,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;

    $.ajax({
      method: 'POST',
      url: mainURL + 'scaffold',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Andamio agregado con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    reader.onload = (e) => {
      let image = e.target.result;
      setModel({ ...model, base64Image: image });
    };
    reader.readAsDataURL(file);
  };

  const disableButton = useMemo(
    () =>
      model.totalQuantity === '' ||
      model.rentedQuantity === '' ||
      model.commercialPrice === '' ||
      model.scaffoldCategoryId === '' ||
      model.name === '',
    [model],
  );

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Crear andamio'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <TextField
                  label={'Nombre'}
                  onChange={handleChange}
                  value={model.name}
                  variant="standard"
                  name="name"
                  margin="dense"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <TextField
                  label={'Referencia'}
                  onChange={handleChange}
                  value={model.reference}
                  variant="standard"
                  name="reference"
                  margin="dense"
                  type="text"
                  fullWidth
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <SelectScaffoldCategory
                name={'scaffoldCategoryId'}
                value={model.scaffoldCategoryId}
                handleChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth required>
                <TextField
                  value={model.totalQuantity}
                  label={'Cantidad total'}
                  onChange={handleChange}
                  name="totalQuantity"
                  variant="standard"
                  margin="dense"
                  type="number"
                  inputProps={{ step: '1' }}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth required>
                <TextField
                  value={model.rentedQuantity}
                  label={'Cantidad alquilada'}
                  onChange={handleChange}
                  name="rentedQuantity"
                  variant="standard"
                  margin="dense"
                  type="number"
                  inputProps={{ step: '1', max: model.totalQuantity }}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth required>
                <TextField
                  value={model.commercialPrice}
                  label={'Precio comercial'}
                  onChange={handleChange}
                  name="commercialPrice"
                  variant="standard"
                  margin="dense"
                  inputProps={{ step: '0.1' }}
                  type="number"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} container alignItems={'flex-end'} justifyContent={'center'}>
              <Typography variant="body1">{`$ ${numberWithCommas(
                model.commercialPrice,
              )}`}</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel variant="standard">{'Imagen'}</InputLabel>
                <Input type="file" onChange={handleFileChange} />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" disabled={disableButton} onClick={handleSubmit}>
              Agregar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
