import React, { useState, useEffect } from 'react';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import ConstructionIcon from '@mui/icons-material/Construction';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import List from '@mui/material/List';

import mainURL from '../../config/environment';
import $ from 'jquery';

export default function TicketHistoryCard(props) {
  const [history, setHistory] = useState([]);

  const { endpoint } = props;
  const { objectId } = props;
  const { title } = props;
  const { type } = props;

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;

    if (objectId !== '') {
      $.ajax({
        method: 'GET',
        url: mainURL + `maintenance-ticket/${endpoint}/${objectId}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).done((res) => {
        if (isSubscribed) {
          let aux = res.filter((x) => x.maintenanceType === type);
          //else aux = res.filter((x) => !x.isRepair);
          setHistory(aux);
        }
      });
    }

    return () => (isSubscribed = false);
  }, [objectId, endpoint, type]);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title={<Typography variant="h5">{title}</Typography>} />
      <CardContent>
        <List sx={{ width: '100%' }}>
          {history.map((step, index) => (
            <React.Fragment key={index}>
              <ListItemButton
                alignItems="flex-start"
                key={step.id}
                sx={{ width: '100%' }}
                onClick={handleClick}
              >
                <ListItemAvatar>
                  <Avatar>
                    <ConstructionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${step.constructionName} - ${step.remission}`}
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {`${new Date(step.startDate).toLocaleDateString()} - ${
                        step.isClosed ? new Date(step.endDate).toLocaleDateString() : 'Abierto'
                      }`}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" sx={{ paddingLeft: '3rem' }}>
                  {step.controlEntries.map((entry) => (
                    <ListItem alignItems="flex-start" key={step.id} sx={{ width: '100%' }}>
                      <ListItemText
                        primary={entry.operator}
                        secondary={`${entry.workedHours} Horas laboradas - $ ${numberWithCommas(
                          entry.totalPrice,
                        )}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
