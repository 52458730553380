import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import $ from 'jquery';
import mainURL from '../../config/environment';

const useConstructions = (refresh) => {
  const [constructions, setConstructions] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + 'construction/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      const filtered = res.filter((x) => x.clientName !== 'Alquicom');
      if (isSubscribed) setConstructions(filtered);
    });
    return () => (isSubscribed = false);
  }, [refresh]);
  return constructions;
};

export default function SelectConstruction(props) {
  const { handleChange } = props;
  const { required } = props;
  const { refresh } = props;
  const { value } = props;
  const { title } = props;
  const { name } = props;

  const constructions = useConstructions(refresh);

  return (
    <FormControl fullWidth required={required}>
      <InputLabel variant="standard">{title ?? 'Obra'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value="" />
        {constructions.map((construction) => (
          <option key={construction.id} value={construction.id}>
            {`${construction.clientName} - ${construction.name}`}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
