import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Home from './home';

import { PermissionContext } from '../../config/permissionContext';

import mainURL from '../../config/environment';
import $ from 'jquery';

export default function ValidateSession(props) {
  const isLoggedin = Boolean(localStorage.getItem('userInfo'));
  const [isValidSession, setValidSession] = useState(isLoggedin);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (!isLoggedin) {
      setValidSession(isLoggedin);
    } else {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const roleId = JSON.parse(localStorage.getItem('userInfo')).roleId;
      let isSubscribed = true;
      $.ajax({
        method: 'GET',
        url: mainURL + `permissions/${roleId}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .done((res) => {
          const aux = [];
          res.forEach((element) => {
            if (element.isAssigned) aux.push(element.permission);
          });
          if (isSubscribed) {
            setValidSession(true);
            setPermissions(aux);
          }
        })
        .fail((res) => {
          if (res.status === 401) {
            setValidSession(false);
          }
        });
      return () => (isSubscribed = false);
    }
  }, [isLoggedin]);

  if (isValidSession) {
    return (
      <PermissionContext.Provider value={permissions}>
        <Home />
      </PermissionContext.Provider>
    );
  } else {
    return <Navigate to="/login" />;
  }
}
