import React, { useState, useEffect, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import $ from 'jquery';
import mainURL from '../../config/environment';

/* const emptyScaffold = {
  rentalId: "",
  returnStatus: "",
  rentalPrice: 0,
}; */

const emptyModel = {
  rentalId: '',
  returnStatus: '',
  rentalPrice: 0,
  returnedQuantity: 0,
  replaceMentQuantity: 0,
};

const useScaffoldRentals = (constructionId) => {
  const [scaffolds, setScaffolds] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}scaffold-rental/get-all?constructionId=${constructionId}&includeClosed=false`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) setScaffolds(res);
    });
    return () => (isSubscribed = false);
  }, [constructionId]);
  return scaffolds;
};

export default function ScaffoldRentalQuantityInput(props) {
  const { constructionId, usedScaffolds } = props;

  const scaffolds = useScaffoldRentals(constructionId);

  const [selectedReference, setSelectedReference] = useState(null);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [model, setModel] = useState(emptyModel);

  //const [isFormComplete, setFormComplete] = useState(false);

  const handleScaffoldChange = (newValue) => {
    if (newValue !== null) {
      setSelectedReference(newValue);
      setModel({
        ...model,
        rentalId: newValue.id,
        name: newValue.name,
        returnedQuantity: newValue.quantity,
      });
      setMaxQuantity(newValue.quantity);
    } else {
      setSelectedReference(null);
    }
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setModel({ ...model, [name]: value });
  };

  const handleSubmit = (event) => {
    props.handleAdd(model);
    setModel(emptyModel);
    setSelectedReference(null);
  };

  const disableButton = useMemo(
    () =>
      model.rentalPrice === '' ||
      model.replaceMentQuantity === '' ||
      model.replaceMentQuantity === '' ||
      model.rentalId === '',
    [model],
  );

  return (
    <Grid container alignItems={'center'} spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h6">Andamios</Typography>
      </Grid>
      <Grid item sm={12} container spacing={2} alignItems={'baseline'}>
        <Grid item sm={12}>
          <FormControl required fullWidth sx={{ mt: 1 }}>
            <Autocomplete
              id="tags-standard"
              options={scaffolds.filter((x) => !usedScaffolds.includes(x.id))}
              getOptionLabel={(opt) => opt.name}
              value={selectedReference}
              onChange={(event, newValue) => {
                handleScaffoldChange(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Andamios" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          <FormControl required fullWidth>
            <TextField
              label={'Cantidad retornada'}
              onChange={handleChange}
              value={model.returnedQuantity}
              variant="standard"
              name="returnedQuantity"
              margin="dense"
              type="number"
              inputProps={{ max: maxQuantity }}
              fullWidth
              autoFocus
              required
            />
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          <FormControl required fullWidth>
            <TextField
              label={'Cantidad a reemplazar'}
              onChange={handleChange}
              value={model.replaceMentQuantity}
              variant="standard"
              name="replaceMentQuantity"
              margin="dense"
              type="number"
              inputProps={{
                max: maxQuantity - (model.returnedQuantity === '' ? 0 : model.returnedQuantity),
              }}
              fullWidth
              required
            />
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          <FormControl required fullWidth>
            <TextField
              label={'Precio unitario'}
              onChange={handleChange}
              value={model.rentalPrice}
              variant="standard"
              name="rentalPrice"
              margin="dense"
              type="number"
              inputProps={{ step: 0.1 }}
              fullWidth
              required
            />
          </FormControl>
        </Grid>
        {/* <Grid item sm={2}>
          <IconButton
          //onClick={handleAddScaffold}
          //disabled={scaffold === emptyScaffold}
          >
            <Tooltip title="Agregar andamio">
              <CheckCircle
              //color={scaffold === emptyScaffold ? "action" : "primary"}
              />
            </Tooltip>
          </IconButton>
        </Grid> */}
      </Grid>

      {/*  <Grid item sm={12}>
        <FormControl fullWidth required sx={{ mt: 1 }}>
          <InputLabel variant="standard">{"Categoría"}</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            name="scaffoldCategoryId"
            variant="standard"
            native
          >
            <option value="" />
            {categories.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item sm={12} container justifyContent={'flex-end'}>
        <Button disabled={disableButton} onClick={handleSubmit}>
          Agregar
        </Button>
      </Grid>
    </Grid>
  );
}
