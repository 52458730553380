import React, { useState } from 'react';

//MUI
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//Selfmade
import Copyright from './copyright';

//React router dom
import { useNavigate } from 'react-router-dom';

import mainURL from '../../config/environment';
import $ from 'jquery';

export default function ForgotPassword() {
  const navigate = useNavigate();

  //Load management
  const [isLoading, setLoading] = useState(false);

  const [model, setModel] = useState({
    email: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    $.ajax({
      method: 'POST',
      url: mainURL + 'login/forgot-password',
      contentType: 'application/json',
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        localStorage.setItem('userInfo', JSON.stringify(res));
        navigate('/login');
      })
      .fail((res) => {
        alert(res.responseText);
      });
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setModel({
      ...model,
      [name]: value,
    });
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img src="assets/images/Logo.png" alt="Logo" />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5, width: '100%' }}>
          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label={'Correo electrónico registrado'}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />

          {isLoading ? (
            <Grid container justifyContent={'center'} sx={{ mt: 3, mb: 2 }}>
              <CircularProgress />
            </Grid>
          ) : (
            <React.Fragment>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Recuperar contraseña
              </Button>
              <Button fullWidth variant="outlined" onClick={() => navigate('/login')}>
                Volver a inicio de sesión
              </Button>
            </React.Fragment>
          )}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
