import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function SelectMaintenanceType(props) {
  const { handleChange } = props;

  const { value } = props;
  const { name } = props;

  return (
    <FormControl fullWidth required sx={{ mt: 1 }}>
      <InputLabel variant="standard">{'Tipo de mantenimiento'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value="" />
        <option value={1}>{'Preventivo'}</option>
        <option value={2}>{'Correctivo'}</option>
      </Select>
    </FormControl>
  );
}
