import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

//Modules
import ConstructionModule from '../../components/modules/construction';
import EquipmentsModule from '../../components/modules/equipments';

import mainURL from '../../config/environment';
import $ from 'jquery';
import RemissionModule from '../../components/modules/remissions';
import DevolutionModule from '../../components/modules/devolutions';

const emptyData = {
  id: '',
  name: '',
  email: '',
  phoneNumber: '',
};

export default function ClientDetails(props) {
  const { clientId } = useParams();

  const [client, setClient] = useState(emptyData);
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //Client details
  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    $.ajax({
      method: 'GET',
      url: `${mainURL}client/${clientId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) {
        setClient(res);
      }
    });
    return () => (isSubscribed = false);
  }, [clientId]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <Grid sx={{ p: '1rem 0' }} spacing={2} container>
        <Grid item sm={12}>
          <Typography variant="h4">{client.name}</Typography>
        </Grid>

        <Grid item sm={12} container>
          <ConstructionModule />
        </Grid>

        <Grid container item sm={12}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'background.paper' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Equipos" value="1" />
                  <Tab label="Remisiones" value="2" />
                  <Tab label="Devoluciones" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <EquipmentsModule />
              </TabPanel>
              <TabPanel value="2">
                <RemissionModule />
              </TabPanel>
              <TabPanel value="3">
                <DevolutionModule />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
