import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import $ from 'jquery';
import mainURL from '../../config/environment';

const emptyModel = {
  id: '',
  name: '',
};

const useScaffolds = () => {
  const [scaffolds, setScaffolds] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}scaffold/get-all`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) setScaffolds(res);
    });
    return () => (isSubscribed = false);
  }, []);
  return scaffolds;
};

export default function SearchAndSelectScaffoldInput(props) {
  const { usedScaffolds } = props;

  const scaffolds = useScaffolds();

  const [model, setModel] = useState(emptyModel);
  const [selectedReference, setSelectedReference] = useState(null);

  const handleChange = (newValue) => {
    if (newValue !== null) {
      setSelectedReference(newValue);
      setModel({
        ...model,
        id: newValue.id,
        name: `${newValue.name} ${newValue.reference} #${newValue.identifier}`,
      });

      props.handleAdd({
        ...model,
        id: newValue.id,
        name: `${newValue.name} ${newValue.reference} #${newValue.identifier}`,
      });
    } else {
      setModel(emptyModel);
      setSelectedReference(null);
    }
  };

  return (
    <FormControl required fullWidth>
      <Autocomplete
        id="tags-standard"
        options={scaffolds.filter((x) => !usedScaffolds.includes(x.id))}
        getOptionLabel={(opt) => opt.name}
        value={selectedReference}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        renderInput={(params) => <TextField {...params} variant="standard" label="Andamio" />}
      />
    </FormControl>
  );
}
