import React, { useState } from 'react';

import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

import UpdateDailyControlDialog from '../../components/dialogs/updateMaintenanceDailyControl';
import DeleteDailyControlDialog from '../../components/dialogs/deleteGeneric';

export default function MaintenanceControlCard(props) {
  const { data, refresh, setRefresh, handleShowNotification, maintenanceId } = props;

  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleCloseDialogs = () => {
    setDeleteDialog(false);
    setUpdateDialog(false);
  };

  return (
    <React.Fragment>
      <DeleteDailyControlDialog
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        name={`el registro de la fecha ${data.date.split('T')[0]}`}
        title={'Eliminar registro'}
        handleClose={handleCloseDialogs}
        deleteURL={`maintenance-daily-control/${data.id}`}
        handleShowNotification={handleShowNotification}
      />
      <UpdateDailyControlDialog
        controlId={data.id}
        maintenanceId={maintenanceId}
        open={updateDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {`${new Date(data.date).toLocaleDateString()} - ${data.technician}`}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Diagnóstico:</Typography>
              <Typography variant="body1" color={'secondary'}>
                {data.diagnostic}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Repuestos:</Typography>
              {data.supplies.map((supply) => (
                <Chip key={supply.supplyId} label={`${supply.supplyName}  (${supply.quantity})`} />
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Button onClick={() => setUpdateDialog(true)}>Editar</Button>
          <Button onClick={() => setDeleteDialog(true)}>Eliminar</Button>
        </AccordionActions>
      </Accordion>
    </React.Fragment>
  );
}
