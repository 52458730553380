import React, { useState, useEffect } from 'react';

import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import List from '@mui/material/List';

import { useNavigate } from 'react-router-dom';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EventIcon from '@mui/icons-material/Event';

import mainURL from '../../config/environment';
import $ from 'jquery';

export default function RentalHistoryCard(props) {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();

  const { endpoint } = props;
  const { objectId } = props;

  const handleClick = (item, type) => (event) => {
    if (type === 'remission') navigate(`/remisiones/${item.remissionId}`);
    else navigate(`/devoluciones/${item.devolutionId}`);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;

    if (objectId !== '') {
      $.ajax({
        method: 'GET',
        url: mainURL + `rental/${endpoint}/${objectId}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).done((res) => {
        if (isSubscribed) {
          console.log(res);
          setHistory(res);
        }
      });
    }

    return () => (isSubscribed = false);
  }, [objectId, endpoint]);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title={<Typography variant="h5">{'Historial de alquiler'}</Typography>} />
      <CardContent>
        <List sx={{ width: '100%' }}>
          {history.map((step, index) => (
            <ListItem
              alignItems="flex-start"
              key={step.id}
              sx={{ width: '100%' }}
              /* onClick={handleClick(step)}
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <ExitToAppIcon color="primary" />
                </IconButton>
              } */
            >
              <ListItemAvatar>
                <Avatar>
                  <EventIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={step.constructionName}
                secondary={
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {`${new Date(step.startDate).toLocaleDateString()} - ${
                      step.devolution ? new Date(step.endDate).toLocaleDateString() : 'Abierto'
                    }`}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Grid>
                  <Tooltip title="Ver remisión">
                    <IconButton onClick={handleClick(step, 'remission')}>
                      <FileUploadIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  {step.devolutionId ? (
                    <Tooltip title="Ver devolución">
                      <IconButton onClick={handleClick(step, 'devolution')}>
                        <FileDownloadIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
