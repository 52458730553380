import React, { useState, useEffect, useRef } from 'react';

//Charts
import ReactEcharts from 'echarts-for-react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';

import { useNavigate } from 'react-router-dom';

//Jquery
import apiURL from '../../config/environment';
import $ from 'jquery';

export default function LineChart(props) {
  const [chartSettings, setChartSettings] = useState({});
  const { endpoint } = props;
  const { title } = props;

  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;

    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;

    $.ajax({
      method: 'GET',
      url: apiURL + endpoint,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    })
      .done((res) => {
        const months = [];
        const rentals = [];
        const tickets = [];

        res.forEach((element) => {
          months.push(element.month);
          rentals.push(element.rentals);
          tickets.push(element.tickets);
        });

        if (isSubscribed) {
          setChartSettings({
            color: ['#0051D5', '#0D9302'],

            // Global text styles
            textStyle: {
              fontFamily: 'Roboto, Arial, Verdana, sans-serif',
              fontSize: 13,
            },

            // Cha23rt animation duration
            animationDuration: 750,

            // Setup grid
            grid: {
              left: 10,
              right: 10,
              top: 35,
              bottom: 10,
              containLabel: true,
            },

            /* title: {
            text: "Number of accidents over the months",
            //subtext: subTitle,
            left: "center",
            textStyle: {
              fontSize: 17,
              fontWeight: 500,
            },
            subtextStyle: {
              fontSize: 12,
            },
            margin: 20,
          }, */

            // Add legend
            legend: {
              data: ['Alquileres', 'Mantenimientos'],
              itemHeight: 5,
              itemGap: 5,
              top: 'top',
            },

            // Add tooltip
            tooltip: {
              trigger: 'axis',
              //backgroundColor: "rgba(0,0,0,0.75)",
              padding: [10, 15],
              textStyle: {
                fontSize: 13,
                fontFamily: 'Roboto, sans-serif',
              },
            },

            // Horizontal axis
            xAxis: [
              {
                type: 'category',
                boundaryGap: true,
                axisLabel: {
                  color: '#333',
                },
                axisLine: {
                  lineStyle: {
                    color: '#999',
                  },
                }, //Fechas
                data: months,
              },
            ],

            // Vertical axis
            yAxis: [
              {
                type: 'value',
                axisLabel: {
                  formatter: '{value} ',
                  color: '#333',
                },
                axisLine: {
                  lineStyle: {
                    color: '#999',
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: ['#eee'],
                  },
                },
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)'],
                  },
                },
              },
            ],

            // Add series
            series: [
              {
                name: 'Alquileres',
                type: months.length > 3 ? 'line' : 'bar',
                smooth: true,
                symbolSize: 6,
                itemStyle: {
                  //color: "#cbcb35",

                  borderWidth: 2,
                }, //Impression data
                data: rentals,
              },
              {
                name: 'Mantenimientos',
                type: months.length > 3 ? 'line' : 'bar',
                smooth: true,
                symbolSize: 6,
                itemStyle: {
                  borderWidth: 2,
                },
                data: tickets,
              },
            ],
          });
        }
      })
      .fail((res) => {
        if (res.status === 401) {
          alert('Sesión expirada');
          localStorage.removeItem('userInfo');
          navigate('/login');
        }
      });

    return () => (isSubscribed = false);
  }, [endpoint, navigate]);

  return (
    <Card>
      <CardHeader title={title} titleTypographyProps={{ fontSize: '1.2rem' }} />
      <CardContent>
        <ReactEcharts ref={useRef('echarts_react')} option={chartSettings} />
      </CardContent>
    </Card>
  );
}
