import React, { useState, useEffect } from 'react';

//MUI
import Typography from '@mui/material/Typography';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom';

import DeleteClientDialog from '../../components/dialogs/deleteGeneric';
import RenameClientDialog from '../../components/dialogs/renameGeneric';
import CreateClientDialog from '../../components/dialogs/createClient';
import SearchAndCreate from '../../components/input/searchAndCreate';
import ClientPopover from '../../components/popovers/generic';

import { alquicomClientId } from '../../config/appConstants';
import mainURL from '../../config/environment';
import $ from 'jquery';

import ClientCard from '../../components/cards/client';

const emptyData = {
  id: '',
  name: '',
  email: '',
  phoneNumber: '',
  accessToken: '',
};

const errorMessage = 'No se puede borrar este cliente porque hay obras registradas a su nombre';

const options = [];

export default function Clients(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  //Dialog management
  const [renameDialog, setRenameDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const handleCloseDialogs = () => {
    setRenameDialog(false);
    setDeleteDialog(false);
    setCreateDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        setCreateDialog(true);
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'rename':
        setRenameDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => () => {
    navigate(`/clientes/${data.id}`);
  };

  // const showDetails = () => {
  //   navigate(`/clientes/${selectedData.id}`);
  // };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Cargando clientes");
    $.ajax({
      method: 'GET',
      url: mainURL + 'client/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res
          .filter((m) => m.id !== alquicomClientId)
          .sort((a, b) => a.name.localeCompare(b.name));
        if (isSubscribed) {
          setData(aux);
          setFilteredData(aux);
          //handleShowNotification("success", "Clientes cargados con éxito");
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh]);

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => f.name.toLowerCase().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <ClientPopover
        open={anchor}
        showDeleteOption
        showRenameOption
        // showDetails={showDetails}
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
        setRenameDialog={setRenameDialog}
      />
      <DeleteClientDialog
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        title={'Eliminar cliente'}
        errorMessage={errorMessage}
        name={selectedData.name}
        handleClose={handleCloseDialogs}
        deleteURL={`client/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      />
      <RenameClientDialog
        refresh={refresh}
        open={renameDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        title={'Renombrar cliente'}
        handleClose={handleCloseDialogs}
        renameURL={`client/${selectedData.id}/rename`}
        handleShowNotification={handleShowNotification}
      />
      <CreateClientDialog
        refresh={refresh}
        open={createDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: '1rem 0' }}
        spacing={2}
        container
      >
        <Grid item md={8}>
          <Typography variant="h4">{'Clientes'}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            handleOpenDialog={handleOpenDialog}
            handleSearch={handleSearch}
            searchText={searchText}
            options={options}
            permission={10}
          />
        )}
      </Grid>

      <Grid container spacing={2}>
        {filteredData.map((client) => (
          <ClientCard
            key={client.id}
            client={client}
            handleSelect={handleSelect}
            handlePopoverOpen={handlePopoverOpen}
          />
        ))}
      </Grid>
    </Box>
  );
}
