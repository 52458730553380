import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import $ from 'jquery';
import mainURL from '../../config/environment';

//MUI-LAB
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { Clear } from '@mui/icons-material';
import SupplyQuantityInput from '../input/supplyQuantity';
import SelectUpdateType from '../selects/selectUpdateType';

const emptyModel = {
  quantities: [],
  comments: '',
  createdOn: new Date(),
  invoice: '',
  provider: '',
  type: '',
  cost: null,
};

export default function UpdateSupplyInventoryDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const { refresh } = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const auxModel = { ...model, createdOn: new Date(model.createdOn).toISOString() };
    $.ajax({
      method: 'POST',
      url: `${mainURL}supply/update-inventory`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(auxModel),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Inventario actualizado con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    props.setRefresh(!refresh);
  };

  const handleAddSupply = (item) => {
    const a = [...model.quantities];

    a.push(item);
    setModel({ ...model, quantities: a });
  };

  const handleDeleteScaffold = (index) => () => {
    let a = [...model.quantities];
    a.splice(index, 1);

    setModel({ ...model, quantities: a });
  };

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleDateChange = (event) => {
    //const date = new Date(event).toISOString();
    //console.log(date);
    setModel({
      ...model,
      createdOn: event,
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Actualizar inventario de repuestos'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectUpdateType handleChange={handleChange} name={'type'} value={model.type} />
            </Grid>
            <Grid item xs={12}>
              <SupplyQuantityInput handleAdd={handleAddSupply} />
            </Grid>

            <Grid item xs={12} maxHeight={'200px'} overflow={'auto'}>
              <List>
                {model.quantities.map((scaffold, index) => (
                  <ListItem key={scaffold.supplyId}>
                    <ListItemText primary={scaffold.name} secondary={scaffold.quantity} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={handleDeleteScaffold(scaffold, index)}>
                        <Clear />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Fecha'}
                    value={model.createdOn}
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    textField={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label={'Factura'}
                  onChange={handleChange}
                  value={model.invoice}
                  variant="standard"
                  name="invoice"
                  margin="dense"
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label={'Proveedor'}
                  onChange={handleChange}
                  value={model.provider}
                  variant="standard"
                  name="provider"
                  margin="dense"
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label={'Costo'}
                  onChange={handleChange}
                  value={model.cost ?? ''}
                  variant="standard"
                  name="cost"
                  margin="dense"
                  type="number"
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={'Comentarios'}
                  onChange={handleChange}
                  value={model.comments}
                  variant="outlined"
                  name="comments"
                  margin="dense"
                  type="text"
                  multiline
                  rows={5}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" onClick={handleSubmit} disabled={model.quantities.length === 0}>
              Actualizar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
