import React, { useState, useEffect, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import { CheckCircle } from '@mui/icons-material';

import $ from 'jquery';
import mainURL from '../../config/environment';

const emptyScaffold = {
  scaffoldId: '',
  quantity: '',
};

const useScaffolds = () => {
  const [scaffolds, setScaffolds] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}scaffold/get-all`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) setScaffolds(res);
    });
    return () => (isSubscribed = false);
  }, []);
  return scaffolds;
};

export default function ScaffoldQuantityInput(props) {
  const scaffolds = useScaffolds();

  const { usedScaffolds, handleAdd } = props;

  const [selectedReference, setSelectedReference] = useState(null);
  const [scaffold, setScaffold] = useState(emptyScaffold);

  const handleScaffoldChange = (newValue) => {
    if (newValue !== null) {
      setSelectedReference(newValue);
      setScaffold({
        ...scaffold,
        scaffoldId: newValue.id,
        name: newValue.name,
      });
    } else {
      setScaffold(emptyScaffold);
      setSelectedReference(null);
    }
  };

  const handleQuantityChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setScaffold({
      ...scaffold,
      [name]: value,
    });
  };

  /*  const handleSubmit = () => {
    props.handleAdd(scaffold);
    setScaffold(emptyModel);
    setSelectedReference(null);
  }; */

  // const handleAddScaffold = (event) => {
  //   const a = [...model.scaffolds];

  //   a.push(scaffold);
  //   setModel({ ...model, scaffolds: a });

  //   setScaffold(emptyScaffold);
  //   setSelectedReference(null);
  // };

  /* const handleDeleteScaffold = (index) => () => {
    let a = [...model.scaffolds];
    a.splice(index, 1);

    setModel({ ...model, scaffolds: a });
  }; */

  const handleSubmit = (event) => {
    handleAdd(scaffold);
    setScaffold(emptyScaffold);
    setSelectedReference(null);
  };

  const disbleButton = useMemo(
    () => scaffold.quantity === '' || scaffold.scaffoldId === '',
    [scaffold],
  );

  return (
    <Grid container alignItems={'center'} rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Andamios</Typography>
      </Grid>

      <Grid item xs={12} container spacing={2} alignItems={'center'}>
        <Grid item xs={6}>
          <FormControl required fullWidth sx={{ height: '56px', justifyContent: 'flex-end' }}>
            <Autocomplete
              id="tags-standard"
              options={scaffolds.filter(
                (x) => !usedScaffolds.map((m) => m.scaffoldId).includes(x.id),
              )}
              getOptionLabel={(opt) => opt.name}
              value={selectedReference}
              onChange={(event, newValue) => {
                handleScaffoldChange(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Andamios" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl required fullWidth>
            <TextField
              label={'Cantidad'}
              onChange={handleQuantityChange}
              value={scaffold.quantity}
              variant="standard"
              name="quantity"
              margin="dense"
              type="number"
              fullWidth
              autoFocus
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} container justifyContent={'center'}>
          <IconButton onClick={handleSubmit} disabled={disbleButton}>
            <Tooltip title="Agregar andamio">
              <CheckCircle color={disbleButton ? 'action' : 'primary'} />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {usedScaffolds.map((used) => (
          <Chip label={`${used.name} (${used.quantity})`} key={used.id} />
        ))}
      </Grid>
    </Grid>
  );
}
