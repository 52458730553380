import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//Excel export
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

//MUI-LAB
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

import $ from 'jquery';
import mainURL from '../../config/environment';

import ConditionalSelectConstruction from '../selects/conditionalSelectConstruction';
import SelectClient from '../selects/selectClient';

const emptyModel = {
  clientId: '',
  clientName: '',
  constructionId: '',
  start: Date.now(),
  end: Date.now(),
};

export default function ClientReportDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const { endpoint } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
      clientName: target.options[target.selectedIndex].text,
    });
  };

  const handleStartDateChange = (event) => {
    //const date = new Date(event);
    //if (date !== 'Invalid Date') {
    setModel({
      ...model,
      start: event,
    });
    //}
  };

  const handleEndDateChange = (event) => {
    //const date = new Date(event);
    // if (date !== 'Invalid Date') {
    setModel({
      ...model,
      end: event,
    });
    // }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;

    const startDate = new Date(model.start).toISOString().split('T')[0];
    const endDate = new Date(model.end).toISOString().split('T')[0];

    let searchString = `start=${startDate}&end=${endDate}`;
    if (model.constructionId !== '') searchString += `&constructionId=${model.constructionId}`;

    console.log(searchString);
    $.ajax({
      method: 'GET',
      url: mainURL + `report/${model.clientId}/${endpoint}?${searchString}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        console.log(res);
        setLoading(false);
        //props.handleShowNotification("success", "Cliente agregado con éxito");
        const fileName = `Reporte_${model.clientName}_${startDate}_${endDate}_${
          endpoint === 'rentals' ? 'Alquileres' : 'Mantenimientos'
        }`;
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(res);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, {
          bookType: 'xlsx',
          type: 'array',
        });

        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);

        handleClear();
      })
      .fail((res) => {
        setLoading(false);
        if (res.status === 409) {
          handleClear();
        } else {
          //props.handleShowNotification("error", res.responseText);
          handleClear();
        }
      });
  };

  const handleClear = () => {
    props.handleClose();
    setModel(emptyModel);
    //props.setRefresh(!refresh);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Generar reporte por cliente'}</DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <SelectClient
                handleChange={handleChange}
                value={model.clientId}
                name={'clientId'}
                required
              />
            </Grid>
            <Grid item sm={12}>
              <ConditionalSelectConstruction
                value={model.constructionId}
                handleChange={handleChange}
                clientId={model.clientId}
                name="constructionId"
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth required>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Fecha de inicio'}
                    value={model.start}
                    onChange={handleStartDateChange}
                    textField={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth required>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Fecha de finalización'}
                    value={model.end}
                    format="dd/MM/yyyy"
                    onChange={handleEndDateChange}
                    textField={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" onClick={handleSubmit} disabled={model.clientId === ''}>
              Generar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
