import React, { useState, useEffect, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import { IconButton } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import $ from 'jquery';
import mainURL from '../../config/environment';
import SelectReturnStatus from '../selects/selectReturnStatus';

const emptyModel = {
  rentalId: '',
  returnStatus: '',
  rentalPrice: 0,
};

const useRentals = (constructionId) => {
  const [rentals, setRentals] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    let isSubscribed = true;
    if (constructionId !== '') {
      $.ajax({
        method: 'GET',
        url: `${mainURL}rental/get-all?constructionId=${constructionId}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
          hostname: host,
        },
      }).done((res) => {
        console.log(res);
        if (isSubscribed) setRentals(res);
      });
    }
    return () => (isSubscribed = false);
  }, [constructionId]);
  return rentals;
};

export default function SearchAndSelectRentalInput(props) {
  const { usedEquipments, constructionId } = props;

  const rentals = useRentals(constructionId);

  const [model, setModel] = useState(emptyModel);
  const [selectedReference, setSelectedReference] = useState(null);

  const handleAutocompleteChange = (newValue) => {
    if (newValue !== null) {
      setSelectedReference(newValue);
      setModel({
        ...model,
        rentalId: newValue.id,
        name: newValue.name,
      });
    } else {
      setModel(emptyModel);
      setSelectedReference(null);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setModel({ ...model, [name]: value });
  };

  const handleSubmit = () => {
    console.log(model);
    props.handleAdd(model);
    setModel(emptyModel);
    setSelectedReference(null);
  };
  console.log(model);

  const disableButton = useMemo(
    () => model.rentalPrice === '' || model.returnStatus === '' || model.rentalId === '',
    [model],
  );

  return (
    <Grid container alignItems={'center'} spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h6">Equipos</Typography>
      </Grid>
      <Grid item md={12}>
        <FormControl required fullWidth>
          <Autocomplete
            id="tags-standard"
            options={rentals.filter((x) => !usedEquipments.includes(x.id))}
            getOptionLabel={(opt) => opt.name}
            value={selectedReference}
            onChange={(event, newValue) => {
              handleAutocompleteChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label="Equipos" />}
          />
        </FormControl>
      </Grid>
      <Grid item md={5}>
        <FormControl required fullWidth>
          <TextField
            variant="standard"
            label="Precio por unidad"
            name="rentalPrice"
            type="number"
            value={model.rentalPrice}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid item md={5}>
        <SelectReturnStatus
          handleChange={handleChange}
          name="returnStatus"
          value={model.returnStatus}
        />
      </Grid>
      <Grid item md={2} container justifyContent={'center'}>
        <IconButton onClick={handleSubmit} disabled={disableButton}>
          <Tooltip title="Agregar equipo">
            <CheckCircle color={disableButton ? 'action' : 'primary'} />
          </Tooltip>
        </IconButton>
      </Grid>
    </Grid>
  );
}
