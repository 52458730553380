import React, { useState, useEffect } from 'react';

//MUI
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';

import { useNavigate, useParams } from 'react-router-dom';

import CreateMaintenanceControl from '../../components/dialogs/createMaintenanceControl';
import MaintenanceControlCard from '../../components/cards/maintenanceControl';
import SearchAndCreate from '../../components/input/searchAndCreate';
import CloseTicketDialog from '../../components/dialogs/closeTicket';

import mainURL from '../../config/environment';
import $ from 'jquery';

const options = [
  {
    buttonLabelTooltip: 'Cerrar ticket',
    action: 'close-ticket',
    icon: <CloseIcon />,
    buttonLabel: 'Crear',
    showButtonLabel: false,
  },
  {
    buttonLabelTooltip: 'Generar reporte',
    action: 'report',
    icon: <ArticleIcon />,
    buttonLabel: 'Crear',
    showButtonLabel: false,
  },
];

export default function MaintenancelDailyControls(props) {
  //Navigation
  const { maintenanceId } = useParams();
  const { navigate } = useNavigate();

  const [refresh, setRefresh] = useState(false);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);

  //Dialog management
  const [createDialog, setCreateDialog] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const handleCloseDialogs = () => {
    setCloseDialog(false);
    setCreateDialog(false);
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  const handleOpenDialog = (dialog) => (event) => {
    console.log(dialog);
    switch (dialog) {
      case 'create':
        setCreateDialog(true);
        break;

      case 'close-ticket':
        setCloseDialog(true);
        break;

      case 'report':
        navigate('reporte');
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Controls
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    handleShowNotification('info', 'Cargando tabla de control');
    $.ajax({
      method: 'GET',
      url: mainURL + `maintenance-daily-control/${maintenanceId}/get-all`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res.sort(function (a, b) {
          if (new Date(a.date) < new Date(b.date)) {
            return -1;
          }
          return 1;
        });
        if (isSubscribed) {
          setData(aux);
          handleShowNotification('success', 'Tabla de control cargada con éxito');
        }
      })
      .fail((res) => {
        if (res.status === 401) {
          handleShowNotification('error', 'Sesión expirada');
          localStorage.removeItem('userInfo');
          navigate('/login');
        }
      });
    return () => (isSubscribed = false);
  }, [refresh, maintenanceId, navigate]);

  //Equipment name
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;

    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + `maintenance-ticket/${maintenanceId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setTitle(res.equipmentName);
          setDate(res.startDate);
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh, maintenanceId]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <CloseTicketDialog
        refresh={refresh}
        open={closeDialog}
        setRefresh={setRefresh}
        ticketId={maintenanceId}
        handleClose={handleCloseDialogs}
        name={title}
        startDate={date}
        handleShowNotification={handleShowNotification}
      />

      <CreateMaintenanceControl
        maintenanceId={maintenanceId}
        open={createDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={'space-between'}
        sx={{ p: '1rem 0' }}
        alignItems={'center'}
        spacing={2}
        container
      >
        <Grid item container md={8} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item sm={12} md={8}>
            <Typography variant="h5">{`Mantenimiento - ${title}`}</Typography>
          </Grid>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} sm={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            handleOpenDialog={handleOpenDialog}
            handleSearch={handleSearch}
            searchText={searchText}
            showCloseTicketOption
            buttonLabel="Agregar"
            showReportOption
            permission={31}
            hideSearch
            options={options}
          />
        )}
      </Grid>
      <Grid container sx={{ pb: 5 }}>
        {data.map((control) => (
          <MaintenanceControlCard
            key={control.id}
            data={control}
            refresh={refresh}
            setRefresh={setRefresh}
            maintenanceId={maintenanceId}
            handleShowNotification={handleShowNotification}
          />
        ))}
      </Grid>
    </Box>
  );
}
