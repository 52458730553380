import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import $ from 'jquery';
import mainURL from '../../config/environment';
import { alquicomClientId } from '../../config/appConstants';

const useClients = (refresh) => {
  const [clients, setClients] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + 'client/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).done((res) => {
      const aux = res.filter((m) => m.id !== alquicomClientId);
      if (isSubscribed) setClients(aux);
    });
    return () => (isSubscribed = false);
  }, []);
  return clients;
};

export default function SelectClient(props) {
  const { handleChange } = props;
  const { required } = props;
  const { refresh } = props;
  const { value } = props;
  const { name } = props;

  const clients = useClients(refresh);

  return (
    <FormControl fullWidth required={required}>
      <InputLabel variant="standard">{'Cliente'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value="" />
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
