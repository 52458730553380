import React, { useState, useEffect, useMemo } from 'react';

//import CircularProgress from "@mui/material/CircularProgress";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

//import Alert from "@mui/material/Alert";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import EscalatorIcon from '@mui/icons-material/Escalator';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import $ from 'jquery';
import mainURL from '../../config/environment';

import { useNavigate, useParams } from 'react-router-dom';
import ScaffoldCategoryAnswer from '../../components/questions/scaffoldCategoryAnswer';
import { heavyMachineryId } from '../../config/appConstants';
import { Cancel, Delete, PlaylistAdd, PlaylistRemove, Visibility } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import AddEquipmentsToRemissionDialog from '../../components/dialogs/addEquipmentsToRemission';
import AddEquipmentsToDevolutionDialog from '../../components/dialogs/addEquipmentsToDevolution';
import DeleteGenericDialog from '../../components/dialogs/deleteGeneric';
import AddScaffoldsToRemissionDialog from '../../components/dialogs/addScaffoldsToRemission';
import AddScaffoldsToDevolutionDialog from '../../components/dialogs/addScaffoldsToDevolution';
import RentalPopover from '../../components/popovers/rental';

const emptyModel = {
  id: '',
  number: 0,
  constructionId: '',
  constructionName: '',
  clientName: '',
  createdOn: '',
  rentals: [],
  comments: [],
};

export default function RemissionDetails(props) {
  const [data, setData] = useState(emptyModel);
  const { devolutionId } = useParams();
  const { remissionId } = useParams();
  const { type } = props;

  const navigate = useNavigate();
  const [isRemoving, setRemoving] = useState(false);
  const [refresh, setRefresh] = useState(false);

  //Dialog management
  const [addScaDevolutionDialog, setAddScaDevolutionDialog] = useState(false);
  const [addEqDevolutionDialog, setAddEqDevolutionDialog] = useState(false);
  const [addScaRemissionDialog, setAddScaRemissionDialog] = useState(false);
  const [addEqRemissionDialog, setAddEqRemissionDialog] = useState(false);

  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleCloseDialogs = () => {
    setAddScaDevolutionDialog(false);
    setAddEqDevolutionDialog(false);
    setAddScaRemissionDialog(false);
    setAddEqRemissionDialog(false);
    setDeleteDialog(false);
  };

  const handleOpenDialogs = (dialog) => {
    if (type === 'remission') {
      switch (dialog) {
        case 'equipments':
          setAddEqRemissionDialog(true);
          break;

        case 'scaffolds':
          setAddScaRemissionDialog(true);
          break;

        default:
          console.log('None');
      }
    } else {
      switch (dialog) {
        case 'equipments':
          setAddEqDevolutionDialog(true);
          break;

        case 'scaffolds':
          setAddScaDevolutionDialog(true);
          break;

        default:
          console.log('None');
      }
    }
  };

  const handleRemoveRental = (item) => (event) => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    $.ajax({
      method: 'DELETE',
      url: `${mainURL}remission/delete-rental/${item.id}/${item.type}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        setRefresh(!refresh);
      })
      .fail((res) => {
        console.log(res);
      });
  };

  const handleShowNotification = (severity, message) => {
    alert(message);
  };

  const errorMessage = `No se puede borrar esta ${
    type === 'remission' ? 'remisión' : 'devolución'
  } porque hay alquileres que dependen en ella`;

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    const id = type === 'remission' ? remissionId : devolutionId;
    $.ajax({
      method: 'GET',
      url: `${mainURL}${type}/${id}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setData(res);
          console.log(res);
        }
      })
      .fail((res) => {
        console.log(res);
        //handleShowNotification("error", res.responseText);
      });
    return () => (isSubscribed = false);
  }, [type, remissionId, devolutionId, refresh]);

  const title = useMemo(() => (type === 'remission' ? 'Remisión' : 'Devolución'), [type]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <AddEquipmentsToRemissionDialog
        handleClose={handleCloseDialogs}
        open={addEqRemissionDialog}
        remissionId={remissionId}
        setRefresh={setRefresh}
        refresh={refresh}
        data={data}
      />
      <AddScaffoldsToRemissionDialog
        handleClose={handleCloseDialogs}
        open={addScaRemissionDialog}
        remissionId={remissionId}
        setRefresh={setRefresh}
        refresh={refresh}
        data={data}
      />
      <AddEquipmentsToDevolutionDialog
        handleClose={handleCloseDialogs}
        open={addEqDevolutionDialog}
        devolutionId={devolutionId}
        setRefresh={setRefresh}
        refresh={refresh}
        data={data}
      />
      <AddScaffoldsToDevolutionDialog
        handleClose={handleCloseDialogs}
        open={addScaDevolutionDialog}
        devolutionId={devolutionId}
        setRefresh={setRefresh}
        refresh={refresh}
        data={data}
      />
      <RentalPopover
        open={anchor}
        handleClose={handlePopoverClose}
        handleOpenDialog={handleOpenDialogs}
      />
      <DeleteGenericDialog
        refresh={refresh}
        open={deleteDialog}
        title={`Eliminar ${title.toLocaleLowerCase()}`} //{type === 'remission' ? 'Eliminar remisión' : 'Eliminar devolución'}
        setRefresh={setRefresh}
        name={data.number}
        errorMessage={errorMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`${type}}/${data.id}`}
        handleShowNotification={handleShowNotification}
      />
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item container sm={12} lg={6} sx={{ pb: 10 }}>
          <Card sx={{ width: '100%' }}>
            <CardHeader
              title={`${title} número ${data.number}`}
              subheader={`Creada el ${new Date(data.createdOn).toLocaleDateString()}`}
              action={
                <Grid container>
                  {isRemoving ? null : (
                    <Tooltip title="Agregar">
                      <IconButton onClick={handlePopoverOpen}>
                        <PlaylistAdd color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isRemoving ? (
                    <Tooltip title="Cancelar">
                      <IconButton onClick={(e) => setRemoving(!isRemoving)}>
                        <Cancel color="primary" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remover">
                      <IconButton onClick={(e) => setRemoving(!isRemoving)}>
                        <PlaylistRemove color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={`Eliminar ${title.toLocaleLowerCase()}`}>
                    <IconButton onClick={(e) => setDeleteDialog(true)}>
                      <Delete color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              }
            />
            <CardContent>
              <Typography variant="h6">Equipos</Typography>
              <Divider sx={{ pb: 1 }} />
              <List sx={{ p: 2 }}>
                {data.rentals.map((rental, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      isRemoving ? (
                        <IconButton onClick={handleRemoveRental(rental)}>
                          <Delete />
                        </IconButton>
                      ) : (
                        <Grid>
                          <IconButton
                            onClick={() =>
                              rental.category === 'Equipos' || rental.category === 'Maquinaria'
                                ? navigate(`/equipos/${rental.itemId}`)
                                : navigate(`/andamios/${rental.itemId}`)
                            }
                          >
                            <Visibility />
                          </IconButton>
                          {rental.equipmentCategoryId === heavyMachineryId ? (
                            <IconButton onClick={() => navigate(`/alquiler/${rental.id}`)}>
                              <ExitToAppIcon />
                            </IconButton>
                          ) : null}
                        </Grid>
                      )
                    }
                  >
                    <ListItemIcon>
                      {rental.category === 'Equipos' ? (
                        <PrecisionManufacturingIcon />
                      ) : rental.category === 'Maquinaria' ? (
                        <AgricultureIcon />
                      ) : (
                        <EscalatorIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={rental.name}
                      secondary={rental.type === 'Andamios' ? `Cantidad: ${rental.quantity}` : null}
                    />
                  </ListItem>
                ))}
              </List>
              {type === 'remission' ? (
                <React.Fragment>
                  <Typography variant="h6">Comentarios</Typography>
                  <Divider sx={{ pb: 1 }} />

                  {data.comments.length === 0 ? (
                    <Typography variant="body1">{'Sin comentarios'}</Typography>
                  ) : (
                    data.comments.map((comment, index) => (
                      <ScaffoldCategoryAnswer key={index} survey={comment} />
                    ))
                  )}
                </React.Fragment>
              ) : null}

              <Typography variant="h6">Comentarios adicionales</Typography>
              <Divider sx={{ pb: 1 }} />
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="body1">{data.notes}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
