import React from 'react';

//React router
import { useNavigate } from 'react-router-dom';

//MUI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ConstructionIcon from '@mui/icons-material/Construction';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GroupIcon from '@mui/icons-material/Group';

const sections = [
  {
    id: 'remissions',
    title: 'Remisiones',
    icon: <FileUploadIcon />,
    path: '/remisiones',
    permission: 25,
  },

  {
    id: 'devolutions',
    title: 'Devoluciones',
    icon: <FileDownloadIcon />,
    path: '/devoluciones',
    permission: 29,
  },
  {
    id: 'tickets',
    title: 'Mantenimientos',
    icon: <ConstructionIcon />,
    path: '/mantenimientos',
    permission: 41,
  },
  {
    id: 'clients',
    title: 'Clientes',
    icon: <EngineeringIcon />,
    path: '/clientes',
    permission: 9,
  },
  {
    id: 'constructions',
    title: 'Obras',
    icon: <HouseSidingIcon />,
    path: '/obras',
    permission: 13,
  },
  {
    id: 'users',
    title: 'Usuarios',
    icon: <GroupIcon />,
    path: '/usuarios',
    permission: 1,
  },
];

export default function InventoryPopover(props) {
  const navigate = useNavigate();
  const { open } = props;

  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={Boolean(open)}
      anchorEl={open}
      onClose={props.handleClose}
    >
      <List dense>
        {sections.map((section) => (
          <ListItemButton key={section.id} onClick={() => navigate(section.path)}>
            <ListItemIcon>{section.icon}</ListItemIcon>
            <ListItemText primary={section.title} />
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
}
