import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import $ from 'jquery';
import mainURL from '../../config/environment';

//MUI-LAB
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

const emptyModel = {
  rentalId: '',
  operator: '',
  numberOfUnits: 0,
  date: '',
};

export default function CreateRentalControl(props) {
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const { refresh, rentalId } = props;

  const handleDateChange = (event) => {
    // const date = new Date(event);
    // if (date !== 'Invalid Date') {
    setModel({
      ...model,
      date: event,
    });
    // }
  };

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
      rentalId: rentalId,
    });

    if (model.finalValue !== 0 && model.operator !== '') {
      setFormComplete(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const auxModel = { ...model, date: new Date(model.date).toISOString() };
    $.ajax({
      method: 'POST',
      url: mainURL + 'rental-daily-control',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(auxModel),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Control agregado con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    setFormComplete(false);
    props.setRefresh(!refresh);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Agregar control'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Fecha'}
                    value={model.date}
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    TextField={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item sm={8}>
              <FormControl fullWidth required>
                <TextField
                  label={'Nombre del operario'}
                  onChange={handleChange}
                  value={model.operator}
                  variant="standard"
                  name="operator"
                  margin="dense"
                  type="text"
                  fullWidth
                  autoFocus
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth required>
                <TextField
                  label={'Horas laboradas'}
                  onChange={handleChange}
                  value={model.numberOfUnits}
                  variant="standard"
                  name="numberOfUnits"
                  margin="dense"
                  type="number"
                  fullWidth
                  required
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" disabled={!isFormComplete} onClick={handleSubmit}>
              Agregar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
