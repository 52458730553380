import React, { useContext, useMemo } from 'react';

//MUI
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

//Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { PermissionContext } from '../../config/permissionContext';
import { useNavigate } from 'react-router-dom';

export default function SearchAndCreate(props) {
  const { showAdvanceSearchIcon } = props;
  const { buttonLabelTooltip } = props;
  const { handleOpenDialog } = props;
  const { showRefreshIcon } = props;
  const { handleSearch } = props;
  const { buttonLabel } = props;
  const { searchText } = props;
  const { permission } = props;
  const { hideSearch } = props;
  const { title, options } = props;

  const navigate = useNavigate();

  const permissions = useContext(PermissionContext);

  const searchColumnWidth = useMemo(() => (options.length > 1 ? 6 : 8), [options]);
  const addColumnWidth = useMemo(() => (options.length > 0 ? 2 : 4), [options]);
  const reduceColumn = useMemo(() => options.length > 0, [options]);

  const showAddOption = useMemo(
    () => permissions.find((x) => x === permission),
    [permissions, permission],
  );

  return (
    <Grid item xs={12} md={4} columnSpacing={2} container justifyContent={'flex-end'}>
      {!hideSearch ? (
        <Grid item xs={12} md={searchColumnWidth}>
          <FormControl fullWidth>
            <TextField
              label={title ?? 'Buscar por nombre'}
              variant="standard"
              onChange={handleSearch}
              value={searchText}
              InputProps={{
                startAdornment: <SearchIcon color="action" />,
                endAdornment: showAdvanceSearchIcon ? (
                  <IconButton size="small" onClick={handleOpenDialog('search')}>
                    <ArrowDropDownIcon color="action" />
                  </IconButton>
                ) : showRefreshIcon ? (
                  <IconButton size="small" onClick={handleOpenDialog('refresh')}>
                    <ClearIcon color="action" />
                  </IconButton>
                ) : null,
              }}
            />
          </FormControl>
        </Grid>
      ) : null}

      {/*  {options} */}

      {showAddOption ? (
        <Grid item xs={12} md={addColumnWidth} sx={{ textAlign: 'end', alignSelf: 'center' }}>
          <Tooltip title={buttonLabelTooltip ?? 'Crear'}>
            <Button variant="contained" onClick={handleOpenDialog('create')}>
              <PlaylistAddIcon sx={reduceColumn ? null : { mr: 1 }} />
              {reduceColumn ? null : buttonLabel ?? 'Crear'}
            </Button>
          </Tooltip>
        </Grid>
      ) : null}

      {options.map((opt) => (
        <Grid item xs={12} md={2} key={opt.action} sx={{ textAlign: 'end', alignSelf: 'center' }}>
          <Tooltip title={opt.buttonLabelTooltip}>
            <Button
              variant="contained"
              onClick={
                opt.action === 'report' ? () => navigate('reporte') : handleOpenDialog(opt.action)
              }
            >
              {opt.icon}
            </Button>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}
