import React, { useState } from 'react';

import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import ClientReportDialog from '../dialogs/clientReport';

export default function ReportCard(props) {
  const { Icon } = props;
  const { text } = props;
  const { endpoint } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogs = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <ClientReportDialog open={openDialog} endpoint={endpoint} handleClose={handleCloseDialogs} />
      <Card>
        <CardHeader
          title={text}
          action={
            <IconButton onClick={handleOpenDialog}>
              <Icon color="primary" sx={{ fontSize: 50 }} />
            </IconButton>
          }
        />
      </Card>
    </React.Fragment>
  );
}
