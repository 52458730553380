import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function ScaffoldCategoryAnswer(props) {
  const { survey } = props;

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item md={12}>
        <Typography variant="subtitle1">{survey.scaffoldCategoryName}</Typography>
      </Grid>
      {survey.comments.map((comment, index) => (
        <Grid item sm={4} key={index}>
          <Typography variant="body1">{comment.question}</Typography>
          <Typography variant="body2" color="textSecondary">
            {comment.answer}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}
