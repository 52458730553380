import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import $ from 'jquery';
import mainURL from '../../config/environment';
import SelectReturnStatus from '../selects/selectReturnStatus';
import SelectUpdateType from '../selects/selectUpdateType';
import SelectUser from '../selects/selectUser';
import SearchAndSelectSupplyInput from '../input/searchAndSelectSupply';
import SearchAndSelectScaffoldInput from '../input/searchAndSelectScaffold';

const emptyModel = {
  type: 0,
  createdBy: 0,
  supplyId: '',
  scaffoldId: '',
  createdOn: '',
  invoice: '',
};

export default function UpdatesAdvancedSearchDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleAddSupply = (item) => {
    setModel({ ...model, supplyId: item.id });
  };

  const handleAddScaffold = (item) => {
    setModel({ ...model, scaffoldId: item.id });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;

    //const baseUrl = showSupplier ? 'supply/' : 'equipment/';

    let searchString = '';

    if (model.type !== 0) searchString += `type=${model.type}&`;

    if (model.createdBy !== 0) searchString += `createdBy=${model.createdBy}&`;

    if (model.supplyId !== '') searchString += `supplyId=${model.supplyId}&`;

    if (model.scaffoldId !== '') searchString += `scaffoldId=${model.scaffoldId}`;

    if (model.invoice !== '') searchString += `invoice=${model.invoice}`;

    $.ajax({
      method: 'GET',
      url: `${mainURL}inventory-log/search?${searchString}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res.results;
        //props.setData(aux);
        props.setFilteredData(aux);
        props.setHasNextPage(res.hasNext);
        props.setRowCount(res.totalItemCount);
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Búsqueda avanzada'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems={'flex-end'}>
            <Grid item sm={6}>
              <SelectUpdateType name="type" value={model.type} handleChange={handleChange} />
            </Grid>
            <Grid item sm={6}>
              <SelectUser name="createdBy" value={model.createdBy} handleChange={handleChange} />
            </Grid>
            <Grid item sm={12}>
              <SearchAndSelectSupplyInput
                handleAdd={handleAddSupply}
                usedSupplies={[]}
                autoSelect
              />
            </Grid>
            <Grid item sm={12}>
              <SearchAndSelectScaffoldInput
                handleAdd={handleAddScaffold}
                usedScaffolds={[]}
                autoSelect
              />
            </Grid>
            <Grid item sm={6}>
              <SelectReturnStatus
                name="status"
                value={model.status ?? ''}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl fullWidth>
                <TextField
                  label={'Factura'}
                  onChange={handleChange}
                  value={model.invoice}
                  variant="standard"
                  name="invoice"
                  margin="dense"
                  type="text"
                  fullWidth
                  autoFocus
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              Buscar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
