import React, { useState, useEffect } from 'react';

//MUI
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';

import { useNavigate } from 'react-router-dom';

//Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CreateTicketDialog from '../../components/dialogs/createTicket';
import SearchAndCreate from '../../components/input/searchAndCreate';
import CloseTicketDialog from '../../components/dialogs/closeTicket';
import OptionsPopover from '../../components/popovers/generic';

import mainURL from '../../config/environment';
import $ from 'jquery';

const emptyData = {
  id: '',
  equipmentId: '',
  startDate: '',
  endDate: '',
  equipmentName: '',
  commentaries: '',
  supplies: [],
};

const options = [];

export default function MaintenanceTickets(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  //const [includeClosed, setIncludeClosed] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  //Navigation
  const navigate = useNavigate();

  const columns = [
    {
      headerName: 'Tipo',
      field: 'maintenanceType',
      flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Equipo',
      field: 'equipmentName',
      flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Remisión',
      field: 'remission',
      flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Fecha de inicio',
      field: 'startDate',
      flex: 1,
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {params.row.startDate.split('T')[0]}
        </Typography>
      ),
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Fecha de finalización',
      field: 'endDate',
      flex: 1,
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {params.row.isClosed ? params.row.endDate.split('T')[0] : 'Abierto'}
        </Typography>
      ),
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Opciones',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  //Dialog management
  const [closeDialog, setCloseDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialogs = () => {
    setCloseDialog(false);
    setOpenDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        setOpenDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => {
    if (data.row !== selectedData) navigate(`/mantenimientos/${data.id}`);
    else setSelectedData(emptyData);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    handleShowNotification('info', 'Cargando tiquetes');
    $.ajax({
      method: 'GET',
      url: mainURL + `maintenance-ticket/get-all?includeClosed=true`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res;
        console.log(res);
        if (isSubscribed) {
          setData(aux);
          handleShowNotification('success', 'Mantenimientos cargados con éxito');
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh]);

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => f.equipmentName.toString().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <OptionsPopover
        open={anchor}
        showCloseTicketOption
        handleClose={handlePopoverClose}
        setCloseTicketDialog={setCloseDialog}
      />
      <CloseTicketDialog
        refresh={refresh}
        open={closeDialog}
        setRefresh={setRefresh}
        ticketId={selectedData.id}
        handleClose={handleCloseDialogs}
        name={selectedData.equipmentName}
        startDate={selectedData.startDate}
        handleShowNotification={handleShowNotification}
      />
      <CreateTicketDialog
        name={null}
        refresh={refresh}
        open={openDialog}
        equipmentId={null}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: '1rem 0' }}
        spacing={2}
        container
      >
        <Grid item md={8}>
          <Typography variant="h4">{'Mantenimientos'}</Typography>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            handleOpenDialog={handleOpenDialog}
            title={'Buscar por equipo'}
            handleSearch={handleSearch}
            searchText={searchText}
            options={options}
            permission={26}
          />
        )}
      </Grid>

      <Grid container height={'70vh'} sx={{ pb: 5 }}>
        <DataGrid
          selectionModel={selectedData.id === '' ? [] : selectedData.id}
          onRowClick={handleSelect}
          columns={columns}
          disableColumnMenu
          rows={filteredData}
        />
      </Grid>
    </Box>
  );
}

/*  <Grid item xs={12} md={4} columnSpacing={2} container justifyContent={'flex-end'}>
            <Grid item xs={12} md={8}>
              <FormControl>
                <FormControlLabel
                  label="Mostrar tickets cerrados"
                  labelPlacement="end"
                  control={
                    <Switch
                      checked={includeClosed}
                      onChange={(e) => setIncludeClosed(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Tooltip title={'Crear'}>
                <Button variant="contained" onClick={() => setOpenDialog(true)}>
                  <PlaylistAddIcon />
                  {'Crear'}
                </Button>
              </Tooltip>
            </Grid>
          </Grid> */
