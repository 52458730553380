import React, { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import mainURL from '../../config/environment';
import $ from 'jquery';

const useCategories = (endpoint, refresh) => {
  const [clients, setCategories] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + endpoint,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).done((res) => {
      if (isSubscribed) setCategories(res);
    });
    return () => (isSubscribed = false);
  }, [endpoint, refresh]);
  return clients;
};

export default function SelectCategory(props) {
  const { handleChange } = props;
  const { endpoint } = props;
  const { refresh } = props;
  const { value } = props;
  const { name } = props;

  const clients = useCategories(endpoint, refresh);

  return (
    <FormControl fullWidth required sx={{ mt: 1 }}>
      <InputLabel variant="standard">{'Categoría'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value="" />
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
