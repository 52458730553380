import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import HouseSidingIcon from '@mui/icons-material/HouseSiding';

import Grid from '@mui/material/Grid';
import { useTheme } from '@emotion/react';

export default function ConstructionCard(props) {
  const { construction, handlePopoverOpen, handleSelect, selected } = props;

  const theme = useTheme();

  return (
    <Grid item xs={12} md={3} pb={2}>
      <Card
        onClick={handleSelect(construction)}
        sx={{
          cursor: 'pointer',
          ':hover': { backgroundColor: theme.palette.divider },
          backgroundColor: selected ? 'action.selected' : 'background.paper',
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              sx={{ backgroundColor: selected ? 'primary.main' : 'divier' }}
            >
              <HouseSidingIcon />
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={handlePopoverOpen(construction)}>
              <MoreVertIcon />
            </IconButton>
          }
          title={construction.name}
        />
      </Card>
    </Grid>
  );
}
