import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function SelectUpdateType(props) {
  const { handleChange } = props;

  const { value } = props;
  const { name } = props;

  return (
    <FormControl fullWidth sx={{ mt: 1 }}>
      <InputLabel variant="standard">{'Tipo'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value={0} />
        <option value={-1}>{'Egreso'}</option>
        <option value={1}>{'Ingreso'}</option>
      </Select>
    </FormControl>
  );
}
