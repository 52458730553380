import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import $ from 'jquery';
import mainURL from '../../config/environment';
import ScaffoldQuantityInput from '../input/scaffoldQuantity';

const emptyModel = { scaffolds: [] };
export default function AddScaffoldsToRemissionDialog(props) {
  const [isLoading, setLoading] = useState(false);

  const [model, setModel] = useState(emptyModel);
  const { refresh, remissionId, data } = props;

  const handleAddScaffold = (item) => {
    const a = [...model.scaffolds];

    a.push(item);
    setModel({ scaffolds: a });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    console.log(model);
    $.ajax({
      method: 'POST',
      url: `${mainURL}remission/${remissionId}/add-scaffolds`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(model),
    })
      .done((res) => {
        //props.handleShowNotification('success', 'Equipos agregados con éxito');
        handleClear();
      })
      .fail((res) => {
        //props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);

    props.setRefresh(!refresh);
  };

  /* useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    if (remissionId !== '') {
      $.ajax({
        method: 'GET',
        url: `${mainURL}remission/${remissionId}`,
        contentType: 'application/json',
        headers: {
          Authorization: 'Bearer ' + token,
          hostname: host,
        },
      }).done((res) => {
        setData(res);
      });
    }
  }, [remissionId, refresh]); */

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{`Añadir andamios a remisión ${data.number}`}</DialogTitle>
      <DialogContent sx={{ minWidth: '500px' }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <ScaffoldQuantityInput
                handleAdd={handleAddScaffold}
                usedScaffolds={model.scaffolds}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" disabled={model.length === 0} onClick={handleSubmit}>
              Agregar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
