import React, { useState } from 'react';

import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

import { useNavigate } from 'react-router-dom';

import $ from 'jquery';
import mainURL from '../../config/environment';

const emptyModel = { endDate: Date.now() };

export default function CloseTicketDialog(props) {
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);

  const navigate = useNavigate();

  const { startDate } = props;
  const { ticketId } = props;
  const { refresh } = props;
  const { name } = props;

  const handleDateChange = (event) => {
    // const date = new Date(event).toISOString();
    setModel({
      endDate: event,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const auxModel = { ...model, endDate: new Date(model.endDate).toISOString() };
    $.ajax({
      method: 'POST',
      url: `${mainURL}maintenance-ticket/${ticketId}/close`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(auxModel),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Ticket cerrado con éxito');
        navigate('/mantenimientos');
        handleClear();
      })
      .fail((res) => {
        console.log(res);
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    props.setRefresh(!refresh);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{'Cerrar alquiler'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Cerrar alquiler para el equipo ${name}`}</DialogContentText>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={'Fecha'}
              value={model.endDate}
              onChange={handleDateChange}
              format="dd/MM/yyyy"
              minDate={new Date(startDate)}
              textField={(params) => <TextField variant="standard" {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cancelar
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              Cerrar Ticket
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
