import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import { IconButton } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import $ from 'jquery';
import mainURL from '../../config/environment';

const emptyModel = {
  name: '',
  supplyId: '',
  quantity: '',
};

const useSupplies = (refresh) => {
  const [supplies, setSupplies] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + 'supply/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).done((res) => {
      if (isSubscribed) setSupplies(res);
    });
    return () => (isSubscribed = false);
  }, []);
  return supplies;
};

export default function SupplyQuantityInput(props) {
  const { refresh } = props;
  const supplies = useSupplies(refresh);
  const [inputValue, setInputValue] = useState('');
  const [selectedSupply, setSelectedSupply] = useState(null);

  const [model, setModel] = useState(emptyModel);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleAutoCompleteChange = (newValue) => {
    if (newValue !== null) {
      setModel({
        ...model,
        supplyId: newValue.id,
        name: newValue.name,
      });
      setSelectedSupply(newValue);
    }
  };

  const handleSubmit = () => {
    props.handleAdd(model);
    setModel(emptyModel);
  };

  return (
    <Grid container alignItems={'center'} spacing={2}>
      <Grid item md={6}>
        <FormControl required fullWidth>
          <Autocomplete
            options={supplies}
            getOptionLabel={(option) => option.name}
            value={selectedSupply}
            onChange={(event, newValue) => {
              handleAutoCompleteChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={'Repuesto'}
                //placeholder="Favorites"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item md={4}>
        <FormControl required fullWidth>
          <TextField
            label={'Cantidad'}
            onChange={handleChange}
            value={model.quantity}
            variant="standard"
            name="quantity"
            margin="dense"
            type="number"
            fullWidth
            autoFocus
            required
          />
        </FormControl>
      </Grid>
      <Grid item md={2} container justifyContent={'center'}>
        <IconButton onClick={handleSubmit} disabled={model.quantity === 0}>
          <Tooltip title="Agregar repuesto">
            <CheckCircle color={model === emptyModel ? 'action' : 'primary'} />
          </Tooltip>
        </IconButton>
      </Grid>
    </Grid>
  );
}
