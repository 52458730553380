import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function SelectReturnStatus(props) {
  const { handleChange } = props;
  const { value } = props;
  const { name } = props;

  const options = [
    { id: 0, name: 'Bueno' },
    { id: 3, name: 'Por reparar' },
    { id: 4, name: 'Por mantenimiento' },
    { id: 5, name: 'Por reposición' },
  ];

  return (
    <FormControl fullWidth required>
      <InputLabel variant="standard">{'Estado de retorno'}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} variant="standard" native>
        <option value="" />
        {options.map((opt) => (
          <option key={opt.id} value={opt.id}>
            {opt.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
