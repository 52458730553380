import React, { useState, useEffect } from 'react';

//MUI
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';

//Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';

import UpdateSupplyInventoryDialog from '../../components/dialogs/updateSupplyInventoryDialog';
import CreateCategoryDialog from '../../components/dialogs/createCategory';
import AdvancedSearchDialog from '../../components/dialogs/advancedSearch';
import SupplyTicketsDialog from '../../components/dialogs/supplyTickets';
import DeleteSupplyDialog from '../../components/dialogs/deleteGeneric';
import RenameSupplyDialog from '../../components/dialogs/renameGeneric';
import CreateSupplyDialog from '../../components/dialogs/createSupply';
import SearchAndCreate from '../../components/input/searchAndCreate';
import SupplyCategoryTable from '../../components/tables/category';
import OptionsPopover from '../../components/popovers/generic';

import { useWidth } from '../../utils/widthSelector';
import mainURL from '../../config/environment';
import $ from 'jquery';

const emptyData = {
  id: '',
  name: '',
  reference: '',
  quantiy: 0,
  measure: '',
  supplyCategoryId: '',
  categoryName: '',
};

const options = [
  {
    buttonLabelTooltip: 'Descargar',
    action: 'export',
    icon: <DownloadIcon />,
    buttonLabel: 'Crear',
    showButtonLabel: false,
  },
  {
    buttonLabelTooltip: 'Registrar movimiento',
    action: 'update-inventory',
    icon: <EditIcon />,
    buttonLabel: 'Crear',
    showButtonLabel: false,
  },
];

export default function Supplies(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const [showCategory, setShowCategory] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const breakpoint = useWidth();

  const columns = [
    {
      headerName: 'Nombre',
      field: 'name',
      flex: 1,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Referencia',
      field: 'reference',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    {
      headerName: 'Categoría',
      field: 'categoryName',
      flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    {
      headerName: 'Cantidad',
      field: 'quantity',
      //flex: 1,
      align: 'center',
      renderCell: (params) => <span>{params.row.quantiy.toString()}</span>,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      headerName: 'Medida',
      field: 'measure',
      //flex: 1,
      breakpoints: ['md', 'lg', 'xl'],
    },
    {
      headerName: 'Opciones',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      //flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  //Dialog management
  const [updateInventoryDialog, setUpdateInventoryDialog] = useState(false);
  const [advancedSearchDialog, setAdvancedSearchDialog] = useState(false);
  const [categoryDialog, setCategoryDialog] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [renameDialog, setRenameDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);

  const handleCloseDialogs = () => {
    setUpdateInventoryDialog(false);
    setAdvancedSearchDialog(false);
    setCategoryDialog(false);
    setHistoryDialog(false);
    setRenameDialog(false);
    setDeleteDialog(false);
    setCreateDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        if (showCategory) setCategoryDialog(true);
        else setCreateDialog(true);
        break;

      case 'export':
        handleExport();
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'rename':
        setRenameDialog(true);
        break;

      case 'search':
        setAdvancedSearchDialog(true);
        break;

      case 'refresh':
        setSearchText('');
        setRefresh(!refresh);
        break;

      case 'update-inventory':
        setUpdateInventoryDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  const handleExport = () => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;

    $.ajax({
      method: 'GET',
      url: `${mainURL}equipment/export`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
      xhr: function () {
        // Seems like the only way to get access to the xhr object
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        return xhr;
      },
    })
      .done((res) => {
        downloadFile(res);
      })
      .fail((res) => {
        console.log(res);
      });
  };

  const downloadFile = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const fileName = `Inventario de repuestos.xlsx`;
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleChangeView = () => {
    setShowCategory(!showCategory);
  };

  const handleSelect = (data) => {
    if (data.row !== selectedData) navigate(`/repuestos/${data.id}`); //setSelectedData(data.row);
    else setSelectedData(emptyData);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Cargando repuestos");
    $.ajax({
      method: 'GET',
      url: mainURL + 'supply/get-all',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res;
        if (isSubscribed) {
          setData(aux);
          setFilteredData(aux);
          //handleShowNotification("success", "Repuestos cargados con éxito");
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh]);

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = data.filter((f) => `${f.name} ${f.reference}`.toLowerCase().match(myReg));
    setFilteredData(newArray);
  }, [data, searchText]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <OptionsPopover
        open={anchor}
        showRenameOption
        showDeleteOption
        showSupplyTicketHistory
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
        setRenameDialog={setRenameDialog}
        setSupplyTicketDialog={setHistoryDialog}
      />
      <DeleteSupplyDialog
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        title={'Eliminar repuesto'}
        handleClose={handleCloseDialogs}
        deleteURL={`supply/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      />
      <RenameSupplyDialog
        refresh={refresh}
        open={renameDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        title={'Renombrar repuesto'}
        handleClose={handleCloseDialogs}
        renameURL={`supply/${selectedData.id}/rename`}
        handleShowNotification={handleShowNotification}
      />
      <CreateSupplyDialog
        refresh={refresh}
        open={createDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <CreateCategoryDialog
        refresh={refresh}
        open={categoryDialog}
        setRefresh={setRefresh}
        endpoint={'supply-category'}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <SupplyTicketsDialog
        refresh={refresh}
        open={historyDialog}
        setRefresh={setRefresh}
        supplyId={selectedData.id}
        handleClose={handleCloseDialogs}
        name={selectedData.name}
        handleShowNotification={handleShowNotification}
      />
      <AdvancedSearchDialog
        showSupplier
        refresh={refresh}
        setRefresh={setRefresh}
        open={advancedSearchDialog}
        handleClose={handleCloseDialogs}
        setFilteredData={setFilteredData}
        handleShowNotification={handleShowNotification}
      />
      <UpdateSupplyInventoryDialog
        refresh={refresh}
        open={updateInventoryDialog}
        setRefresh={setRefresh}
        categoryId={'categoryId'}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={'space-between'}
        alignItems={'center'}
        //sx={{ height: "15vh" }}
        sx={{ p: '1rem 0' }}
        spacing={2}
        container
      >
        <Grid item md={8}>
          <ToggleButtonGroup exclusive value={showCategory} onChange={handleChangeView}>
            <ToggleButton value={false}>
              <Typography variant="subtitle1">Repuestos</Typography>
            </ToggleButton>
            <ToggleButton value={true}>
              <Typography variant="subtitle1">Categorías</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            showAdvanceSearchIcon={data.length === filteredData.length}
            title={'Buscar por nombre o referencia'}
            showUpdateInventoryOption
            handleOpenDialog={handleOpenDialog}
            handleSearch={handleSearch}
            searchText={searchText}
            options={options}
            showExportOption
            showRefreshIcon
            permission={18}
          />
        )}
      </Grid>

      <Grid container height={'65vh'}>
        {showCategory ? (
          <SupplyCategoryTable
            handleShowNotification={handleShowNotification}
            endpoint={'supply-category/get-all'}
            refreshCategory={refresh}
          />
        ) : (
          <DataGrid
            selectionModel={selectedData.id === '' ? [] : selectedData.id}
            onRowClick={handleSelect}
            rows={filteredData}
            columns={columns.filter((m) => m.breakpoints.includes(breakpoint))}
            disableColumnMenu
            autoPageSize
          />
        )}
      </Grid>
    </Box>
  );
}
