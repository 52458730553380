import React, { useState } from 'react';

import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';

//Selfmade
import ProfilePopover from '../popovers/profile';
import Button from '@mui/material/Button';
import ActiveLastBreadcrumb from './breadcrum';
import { useWidth } from '../../utils/widthSelector';

export default function Navbar() {
  const navbarName =
    JSON.parse(localStorage.getItem('userInfo')) === null
      ? 'Preztel'
      : JSON.parse(localStorage.getItem('userInfo')).fullName;

  const breakpoint = useWidth();

  //Profile popover management
  const [anchorProfile, setAnchorProfile] = useState(null);
  const handleProfilePopoverOpen = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfilePopoverClose = () => {
    setAnchorProfile(null);
  };

  function getFirstLetters(sentence) {
    // Split the sentence into words using a space as a delimiter
    const words = sentence.split(' ');

    // Map over the words to extract the first letter of each word
    const firstLetters = words.map((word) => word.charAt(0));

    // Join the first letters into a single string
    return firstLetters.join('');
  }

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <ProfilePopover open={anchorProfile} handleClose={handleProfilePopoverClose} />
      <Toolbar>
        <Grid container justifyContent={'space-between'}>
          <ActiveLastBreadcrumb />
          {}
          <Button variant="outlined" color="inherit" onClick={handleProfilePopoverOpen}>
            {['md', 'lg', 'xl'].includes(breakpoint) ? navbarName : getFirstLetters(navbarName)}
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
