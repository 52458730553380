import React, { useContext } from 'react';

//MUI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';

import EscalatorIcon from '@mui/icons-material/Escalator';
import SettingsIcon from '@mui/icons-material/Settings';

import { PermissionContext } from '../../config/permissionContext';

const sections = [
  {
    id: 'supplies',
    title: 'Repuestos',
    icon: <SettingsIcon />,
    path: '/repuestos',
    permission: 21,
  },
  {
    id: 'scaffolds',
    title: 'Andamios',
    icon: <EscalatorIcon />,
    path: '/andamios',
    permission: 21,
  },
];

export default function UpdatesPopover(props) {
  const permissions = useContext(PermissionContext);
  const { open } = props;

  const handleSelect = (path) => (event) => {
    props.handleOpenDialog(path, event);
    props.handleClose();
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(open)}
      anchorEl={open}
      onClose={props.handleClose}
    >
      <List dense>
        {sections.map((section) => {
          const allow = permissions.find((x) => x === section.permission);
          if (allow) {
            return (
              <ListItemButton key={section.id} onClick={handleSelect(section.id)}>
                <ListItemIcon>{section.icon}</ListItemIcon>
                <ListItemText primary={section.title} />
              </ListItemButton>
            );
          } else return null;
        })}
      </List>
    </Popover>
  );
}
