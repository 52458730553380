import React, { useState, useEffect } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import UpdateSupplyDialog from '../dialogs/updateSupply';
import DeleteSupplyDialog from '../dialogs/deleteGeneric';

import mainURL from '../../config/environment';
import $ from 'jquery';
import FullSizeImage from '../dialogs/fullSizeImage';

const emptyData = {
  id: '',
  name: '',
  base64Image: '',
  reference: '',
  commercialPrice: 0,
  supplier: '',
  quantiy: 0,
  measure: '',
  supplyCategoryId: '',
  categoryName: '',
};

const errorMessage =
  'No se puede borrar este repuesto porque hay alquileres o mantenimientos registrados a su nombre';

export default function SupplyDetailsCard(props) {
  const [data, setData] = useState(emptyData);

  const { setRefresh } = props;
  const { supplyId } = props;
  const { refresh } = props;

  //Dialog management
  const [imageDialog, setImageDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleCloseDialogs = () => {
    setDeleteDialog(false);
    setUpdateDialog(false);
    setImageDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'image':
        setImageDialog(true);
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'update':
        setUpdateDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Cargando equipos");
    console.log(refresh);
    $.ajax({
      method: 'GET',
      url: mainURL + `supply/${supplyId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        console.log(res);
        const aux = res;
        if (isSubscribed) {
          setData(aux);
          //handleShowNotification("success", "Equipos cargados con éxito");
        }
      })
      .fail((res) => {
        //handleShowNotification("error", res.responseText);
      });

    return () => (isSubscribed = false);
  }, [supplyId, refresh]);

  return (
    <React.Fragment>
      <DeleteSupplyDialog
        name={data.name}
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        title={'Eliminar repuesto'}
        errorMessage={errorMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`supply/${data.id}`}
        handleShowNotification={handleShowNotification}
      />
      <UpdateSupplyDialog
        refresh={refresh}
        supplyId={supplyId}
        open={updateDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <FullSizeImage
        open={imageDialog}
        name={data.name}
        image={data.base64Image}
        handleClose={handleCloseDialogs}
      />
      <Card>
        <CardHeader
          avatar={
            <IconButton size="small" onClick={handleOpenDialog('image')}>
              <Avatar src={data.base64Image} sx={{ height: '100px', width: '100px' }} />
            </IconButton>
          }
          title={<Typography variant="h5">{data.name}</Typography>}
          subheader={<Typography variant="subtitle1">{data.reference}</Typography>}
          action={
            showNotification ? (
              <Alert variant="outlined" severity={notificationMessage.severity}>
                {notificationMessage.message}
              </Alert>
            ) : (
              <Grid container>
                <IconButton color="primary" onClick={handleOpenDialog('update')}>
                  <Tooltip title="Editar">
                    <EditIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton color="primary" onClick={handleOpenDialog('delete')}>
                  <Tooltip title="Eliminar">
                    <DeleteIcon fontSize="large" />
                  </Tooltip>
                </IconButton>
              </Grid>
            )
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Categoría'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.categoryName}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Cantidad'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.quantiy}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Unidad de medida'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.measure}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Proveedor'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.supplier}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Precio comercial'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {numberWithCommas(data.commercialPrice)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
