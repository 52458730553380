import React, { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import { IconButton } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import SelectReturnStatus from '../selects/selectReturnStatus';

const emptyModel = {
  rentalId: '',
  returnStatus: '',
  rentalPrice: '',
  name: '',
};

export default function RentalStatusPriceInput(props) {
  const { handleAdd, rentals } = props;

  const [model, setModel] = useState(emptyModel);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'rentalId') {
      const itemName = rentals.filter((x) => x.id === value)[0].equipmentName;
      setModel({
        ...model,
        [name]: value,
        name: itemName,
      });
    } else {
      setModel({
        ...model,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    handleAdd(model);
    setModel(emptyModel);
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <Grid container alignItems={'center'} spacing={2} sx={{ border: '1 px dashed gray' }}>
      <Grid item md={8}>
        <FormControl required fullWidth>
          <InputLabel variant="standard">{'Equipo a devolver'}</InputLabel>
          <Select
            value={model.rentalId}
            onChange={handleChange}
            name="rentalId"
            variant="standard"
            native
          >
            <option value="" />
            {rentals.map((rent) => (
              <option key={rent.id} value={rent.id}>
                {rent.equipmentName}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={4}>
        <SelectReturnStatus
          handleChange={handleChange}
          value={model.returnStatus}
          name="returnStatus"
        />
      </Grid>
      <Grid item md={4}>
        <FormControl required fullWidth>
          <TextField
            label={'Precio por unidad'}
            onChange={handleChange}
            value={model.rentalPrice}
            variant="standard"
            name="rentalPrice"
            margin="dense"
            type="number"
            fullWidth
            autoFocus
            required
          />
        </FormControl>
      </Grid>
      <Grid item sm={4}>
        <Typography textAlign={'end'} variant="body1">{`$ ${numberWithCommas(
          model.rentalPrice,
        )}`}</Typography>
      </Grid>
      <Grid item md={4} container justifyContent={'center'}>
        <IconButton onClick={handleSubmit} disabled={model.quantity === 0}>
          <Tooltip title="Agregar equipo">
            <CheckCircle color={model === emptyModel ? 'action' : 'primary'} />
          </Tooltip>
        </IconButton>
      </Grid>
    </Grid>
  );
}
