import React, { useState, useEffect } from 'react';

//MUI
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';

//Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

import RenameEquipmentDialog from '../dialogs/renameGeneric';
import DeleteSupplyDialog from '../dialogs/deleteGeneric';
import OptionsPopover from '../popovers/generic';

import mainURL from '../../config/environment';
import $ from 'jquery';

const emptyData = {
  id: '',
  name: '',
};

export default function EquipmentCategoryTable(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);
  const { handleShowNotification } = props;
  const { refreshCategory } = props;
  const { endpoint } = props;

  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    {
      headerName: 'Nombre',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'Prefijo',
      field: 'prefix',
      flex: 1,
    },
    {
      headerName: 'Opciones',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
    },
  ];

  //Dialog management
  const [renameDialog, setRenameDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleCloseDialogs = () => {
    setRenameDialog(false);
    setDeleteDialog(false);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: mainURL + endpoint,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res;
        if (isSubscribed) {
          setData(aux);
        }
      })
      .fail((res) => {});

    return () => (isSubscribed = false);
  }, [refresh, endpoint, refreshCategory]);

  return (
    <React.Fragment>
      <OptionsPopover
        open={anchor}
        showRenameOption
        showDeleteOption
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
        setRenameDialog={setRenameDialog}
      />
      <DeleteSupplyDialog
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        title={'Eliminar categoría'}
        handleClose={handleCloseDialogs}
        deleteURL={`supply-category/${selectedData.id}`}
        handleShowNotification={handleShowNotification}
      />
      <RenameEquipmentDialog
        refresh={refresh}
        open={renameDialog}
        setRefresh={setRefresh}
        name={selectedData.name}
        title={'Renombrar categoría'}
        handleClose={handleCloseDialogs}
        renameURL={`supply-category/${selectedData.id}/rename`}
        handleShowNotification={handleShowNotification}
      />
      <DataGrid disableColumnMenu autoPageSize columns={columns} rows={data} />
    </React.Fragment>
  );
}
