import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useParams } from 'react-router-dom';

import EquipmentDetailsCard from '../../components/cards/equipmentDetails';
import TicketHistoryCard from '../../components/cards/ticketHistory';
import RentalHistoryCard from '../../components/cards/rentalHistory';
import SupplyDetailsCard from '../../components/cards/supplyDetails';
import ScaffoldDetailsCard from '../../components/cards/scaffoldDetails';

export default function EquipmentDetails(props) {
  const [refresh, setRefresh] = useState(false);
  const { equipmentId } = useParams();
  const { scaffoldId } = useParams();
  const { supplyId } = useParams();
  const { type } = props;

  console.log(type, scaffoldId);

  return (
    <Box sx={{ height: '85vh', p: 2, overflow: 'auto', pb: 3 }}>
      <Grid container spacing={1}>
        <Grid item md={12}>
          {type === 'Equipment' ? (
            <EquipmentDetailsCard
              endpoint={'get-history'}
              equipmentId={equipmentId}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          ) : type === 'Scaffold' ? (
            <ScaffoldDetailsCard
              endpoint={'get-history'}
              scaffoldId={scaffoldId}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          ) : (
            <SupplyDetailsCard
              endpoint={'get-history'}
              setRefresh={setRefresh}
              supplyId={supplyId}
              refresh={refresh}
            />
          )}
        </Grid>
        {type === 'Equipment' ? (
          <Grid item md={12} container height={'53vh'} spacing={2}>
            <Grid item md={4}>
              <RentalHistoryCard objectId={equipmentId} endpoint={'get-history'} />
            </Grid>
            <Grid item md={4}>
              <TicketHistoryCard
                title="Mantenimientos preventivos"
                objectId={equipmentId}
                endpoint={'get-history'}
                type="Preventivo"
              />
            </Grid>
            <Grid item md={4}>
              <TicketHistoryCard
                title="Mantenimientos correctivos"
                objectId={equipmentId}
                endpoint={'get-history'}
                type="Correctivo"
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}
