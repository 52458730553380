import React, { useState, useEffect } from 'react';

//MUI
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';

//Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CreateRentalControlDialog from '../../components/dialogs/createRentalControl';
import UpdateDailyControlDialog from '../../components/dialogs/updateDailyControl';
import DeleteDailyControlDialog from '../../components/dialogs/deleteGeneric';
import SearchAndCreate from '../../components/input/searchAndCreate';
import OptionsPopover from '../../components/popovers/generic';

import mainURL from '../../config/environment';
import $ from 'jquery';

import { useParams } from 'react-router-dom';

const emptyData = {
  id: '',
  rentalId: '',
  date: '',
  operator: '',
  measureType: '',
  initialValue: 0,
  finalValue: 0,
  totalValue: 0,
  totalPrice: 0,
};

const options = [];

export default function RentalDailyControls(props) {
  //Data management
  const [selectedData, setSelectedData] = useState(emptyData);

  //Navigation
  const { rentalId } = useParams();

  //const [measureType, setMeasureType] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);

  const measureType = '';

  /*  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }; */

  const columns = [
    {
      headerName: 'Fecha',
      field: 'date',
      flex: 1,
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {params.row.date.split('T')[0]}
        </Typography>
      ),
    },
    {
      headerName: 'Operario',
      field: 'operator',
      flex: 1,
    },
    {
      headerName: 'Horas laboradas',
      field: 'numberOfUnits',
      flex: 1,
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {`${params.row.numberOfUnits}`}
        </Typography>
      ),
    },

    /* {
      headerName: "Costo total",
      field: "totalPrice",
      flex: 1,
      renderCell: (params) => (
        <Typography key={params.row.id} variant="body2">
          {numberWithCommas(params.row.totalPrice)}
        </Typography>
      ),
    }, */
    {
      headerName: 'Opciones',
      field: 'id',
      renderCell: (params) => (
        <IconButton onClick={handlePopoverOpen(params.row)}>
          <MoreVertIcon />
        </IconButton>
      ),
      //flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
    },
  ];

  //Dialog management
  const [createDialog, setCreateDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleCloseDialogs = () => {
    setCreateDialog(false);
    setDeleteDialog(false);
    setUpdateDialog(false);
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 2000);
  };

  //Search management
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        setCreateDialog(true);
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'update':
        setUpdateDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedData(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => {
    if (data.row !== selectedData) setSelectedData(data.row);
    else setSelectedData(emptyData);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    handleShowNotification('info', 'Cargando tabla de control');
    $.ajax({
      method: 'GET',
      url: mainURL + `rental-daily-control/${rentalId}/get-all`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        const aux = res;
        if (isSubscribed) {
          setData(aux);
          handleShowNotification('success', 'Tabla de control cargada con éxito');
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh, rentalId]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    $.ajax({
      method: 'GET',
      url: `${mainURL}rental/${rentalId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        console.log(res);
        if (isSubscribed) {
          setTitle(res.name);
          //setMeasureType(res.rentalMeasureType);
        }
      })
      .fail((res) => {
        handleShowNotification('error', res.responseText);
      });
    return () => (isSubscribed = false);
  }, [refresh, rentalId]);

  return (
    <Box sx={{ height: '85vh', p: 2 }}>
      <OptionsPopover
        open={anchor}
        showDeleteOption
        showUpdateOption
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
        setUpdateDialog={setUpdateDialog}
      />
      <DeleteDailyControlDialog
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        title={'Eliminar registro'}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
        deleteURL={`rental-daily-control/${selectedData.id}`}
        name={`el registro de la fecha ${selectedData.date.split('T')[0]}`}
      />
      <UpdateDailyControlDialog
        refresh={refresh}
        rentalId={rentalId}
        open={updateDialog}
        setRefresh={setRefresh}
        measureType={measureType}
        controlId={selectedData.id}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <CreateRentalControlDialog
        rentalId={rentalId}
        open={createDialog}
        setRefresh={setRefresh}
        measureType={measureType}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Grid
        justifyContent={'space-between'}
        sx={{ p: '1rem 0' }}
        alignItems={'center'}
        spacing={2}
        container
      >
        <Grid item container md={8} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={8}>
            <Typography variant="h5">{`Control de alquiler - ${title}`}</Typography>
          </Grid>
        </Grid>

        {showNotification ? (
          <Grid item xs={12} md={4}>
            <Alert variant="outlined" severity={notificationMessage.severity}>
              {notificationMessage.message}
            </Alert>
          </Grid>
        ) : (
          <SearchAndCreate
            handleOpenDialog={handleOpenDialog}
            handleSearch={handleSearch}
            searchText={searchText}
            options={options}
            permission={26}
          />
        )}
      </Grid>

      <Grid container height={'70vh'} sx={{ pb: 5 }}>
        <DataGrid
          selectionModel={selectedData.id === '' ? [] : selectedData.id}
          onRowClick={handleSelect}
          columns={columns}
          disableColumnMenu
          autoPageSize
          rows={data}
        />
      </Grid>
    </Box>
  );
}
