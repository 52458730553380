import React, { useState, useEffect } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import ConstructionIcon from '@mui/icons-material/Construction';
import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';
import EditIcon from '@mui/icons-material/Edit';

import UpdateScaffoldDialog from '../dialogs/updateScaffoldDialog';
import DeleteEquipmentDialog from '../dialogs/deleteGeneric';
import FullSizeImage from '../dialogs/fullSizeImage';

import mainURL from '../../config/environment';
import $ from 'jquery';
import CreateTicketDialog from '../dialogs/createTicket';
import { useNavigate } from 'react-router-dom';

const emptyData = {
  id: '',
  name: '',
  reference: '',
  scaffoldCategoryId: 0,
  categoryName: '',
  totalQuantity: 0,
  rentedQuantity: 0,
  availableQuantity: 0,
  commercialPrice: 0,
};

const errorMessage =
  'No se puede borrar este equipo porque hay alquileres o mantenimientos registrados a su nombre';

export default function ScaffoldDetailsCard(props) {
  const [data, setData] = useState(emptyData);
  const navigate = useNavigate();
  const { scaffoldId } = props;
  const { setRefresh } = props;
  const { refresh } = props;

  //Dialog management
  const [maintenanceDialog, setMaintenanceDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);

  const handleCloseDialogs = () => {
    setMaintenanceDialog(false);
    setDeleteDialog(false);
    setUpdateDialog(false);
    setImageDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'image':
        setImageDialog(true);
        break;

      case 'rent':
        navigate('/remisiones/crear');
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'rename':
        setUpdateDialog(true);
        break;

      case 'maintenance':
        setMaintenanceDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  //Notification management
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    severity: '',
    message: '',
  });

  const handleShowNotification = (severity, message) => {
    setNotificationMessage({ severity: severity, message: message });
    setShowNotification(true);
    setTimeout(function () {
      setShowNotification(false);
    }, 3000);
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    let isSubscribed = true;
    //handleShowNotification("info", "Cargando equipos");
    $.ajax({
      method: 'GET',
      url: `${mainURL}scaffold/${scaffoldId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .done((res) => {
        console.log(res);
        const aux = res;
        if (isSubscribed) {
          setData(aux);
          //handleShowNotification("success", "Equipos cargados con éxito");
        }
      })
      .fail((res) => {
        //handleShowNotification("error", res.responseText);
      });

    return () => (isSubscribed = false);
  }, [scaffoldId, refresh]);

  return (
    <React.Fragment>
      <CreateTicketDialog
        refresh={refresh}
        open={maintenanceDialog}
        setRefresh={setRefresh}
        name={data.name}
        scaffoldId={data.id}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <DeleteEquipmentDialog
        name={data.name}
        refresh={refresh}
        open={deleteDialog}
        setRefresh={setRefresh}
        title={'Eliminar equipo'}
        errorMessage={errorMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`equipment/${data.id}`}
        handleShowNotification={handleShowNotification}
      />
      <FullSizeImage
        open={imageDialog}
        name={data.name}
        image={data.base64Image}
        handleClose={handleCloseDialogs}
      />
      <UpdateScaffoldDialog
        refresh={refresh}
        open={updateDialog}
        setRefresh={setRefresh}
        scaffoldId={scaffoldId}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <Card>
        <CardHeader
          avatar={
            <IconButton size="small" onClick={handleOpenDialog('image')}>
              <Avatar src={data.base64Image} sx={{ height: '100px', width: '100px' }} />
            </IconButton>
          }
          title={<Typography variant="h5">{data.name}</Typography>}
          subheader={<Typography variant="subtitle1">{`${data.reference}`}</Typography>}
          action={
            showNotification ? (
              <Alert variant="outlined" severity={notificationMessage.severity}>
                {notificationMessage.message}
              </Alert>
            ) : (
              <Grid container>
                <IconButton
                  color="primary"
                  disabled={data.status === 'Alquilado'}
                  onClick={handleOpenDialog('rent')}
                >
                  <Tooltip title="Alquilar">
                    <EventIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton
                  color="primary"
                  disabled={data.status === 'Alquilado'}
                  onClick={handleOpenDialog('maintenance')}
                >
                  <Tooltip title="Abrir mantenimiento">
                    <ConstructionIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton color="primary" onClick={handleOpenDialog('rename')}>
                  <Tooltip title="Editar">
                    <EditIcon fontSize="large" />
                  </Tooltip>
                </IconButton>

                <IconButton color="primary" onClick={handleOpenDialog('delete')}>
                  <Tooltip title="Eliminar">
                    <DeleteIcon fontSize="large" />
                  </Tooltip>
                </IconButton>
              </Grid>
            )
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Categoría'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.categoryName}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Cantidad disponible'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.availableQuantity}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Cantidad alquilada'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {data.rentedQuantity}
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography variant="body1">{'Precio comercial'}</Typography>
              <Typography variant="body2" color="textSecondary">
                {numberWithCommas(data.commercialPrice)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
