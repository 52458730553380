import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

import SupplyQuantityInput from '../input/supplyQuantity';

//MUI-LAB
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

import $ from 'jquery';
import mainURL from '../../config/environment';

const emptyModel = {
  maintenanceId: '',
  date: new Date(),
  workedHours: '',
  diagnostic: '',
  images: [],
  supplies: [],
};

export default function CreateMaintenanceControl(props) {
  const [isFormComplete, setFormComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const { refresh, maintenanceId } = props;

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    setModel({
      ...model,
      [name]: value,
      maintenanceId: maintenanceId,
    });

    if (model.finalValue !== 0 && model.technician !== '') {
      setFormComplete(true);
    }
  };

  const handleFileChange = (event) => {
    const value = event.target.files;

    const files = Array.from(value);

    const modelFiles = [];
    files.forEach((f) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        let image = e.target.result;
        modelFiles.push(image);
      };
      reader.readAsDataURL(f);
    });
    setModel({ ...model, images: modelFiles });
  };

  const handleDateChange = (event) => {
    //const date = new Date(event);
    //if (date !== 'Invalid Date') {
    setModel({
      ...model,
      date: event,
    });
    //}
  };

  const handleAdd = (item) => {
    const a = [...model.supplies];
    a.push(item);
    setModel({ ...model, supplies: a });
  };

  const handleDelete = (index) => () => {
    let a = [...model.supplies];
    a.splice(index, 1);
    setModel({ ...model, supplies: a });
  };

  /*  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }; */

  const handleCancelUploadFiles = (event) => {
    // const target = event.target.value;
    setModel({ ...model, images: [] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let newModel = { ...model, date: new Date(model.date).toISOString() };
    if (model.supplyId === '' || model.supplyQuantity === 0) {
      newModel = { ...model, supplyId: null };
    }
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    $.ajax({
      method: 'POST',
      url: mainURL + 'maintenance-daily-control',
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: JSON.stringify(newModel),
    })
      .done((res) => {
        props.handleShowNotification('success', 'Control agregado con éxito');
        handleClear();
      })
      .fail((res) => {
        props.handleShowNotification('error', res.responseText);
        handleClear();
      });
  };

  const handleClear = () => {
    setLoading(false);
    props.handleClose();
    setModel(emptyModel);
    setFormComplete(false);
    props.setRefresh(!refresh);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
      <DialogTitle>{'Agregar control'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} pt={2}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Fecha'}
                    value={model.date}
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    textField={(params) => <TextField variant="standard" {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <TextField
                  label={'Horas laboradas'}
                  onChange={handleChange}
                  value={model.workedHours}
                  variant="standard"
                  name="workedHours"
                  margin="dense"
                  type="number"
                  fullWidth
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <SupplyQuantityInput handleAdd={handleAdd} />
            </Grid>

            <Grid item xs={12} container>
              <Stack direction="row" spacing={1}>
                {model.supplies.map((supply, index) => (
                  <Chip
                    key={index}
                    label={`${supply.name}  (${supply.quantity})`}
                    onDelete={handleDelete(index)}
                  />
                ))}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <TextField
                  label="Diagnóstico"
                  rows={5}
                  multiline
                  name="diagnostic"
                  id={model.diagnostic}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel variant="standard">{'Imágenes'}</InputLabel>
                <Input
                  type="file"
                  inputProps={{ multiple: true }}
                  onChange={handleFileChange}
                  endAdornment={
                    <IconButton onClick={handleCancelUploadFiles}>
                      <CancelIcon />
                    </IconButton>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Grid container justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={'flex-end'}>
            <Button type="submit" onClick={handleClear}>
              Cerrar
            </Button>
            <Button type="submit" disabled={!isFormComplete} onClick={handleSubmit}>
              Agregar
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
