import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import CreateConstructionDialog from '../../components/dialogs/createConstruction';
import RenameConstructionDialog from '../../components/dialogs/renameGeneric';
import DeleteConstructionDialog from '../../components/dialogs/deleteGeneric';
import SearchAndCreate from '../../components/input/searchAndCreate';
import ConstructionPopover from '../../components/popovers/generic';
import ConstructionCard from '../../components/cards/construction';

import mainURL from '../../config/environment';
import $ from 'jquery';

const emptyConstruction = {
  id: '',
  name: '',
  clientId: '',
  clientName: '',
};

const errorMessage = 'No se puede borrar esta obra porque hay equipos ubicados en ella';

const options = [];

export default function ConstructionModule(props) {
  const { clientId, constructionId } = useParams();

  const [selectedConstruction, setSelectedConstruction] = useState(emptyConstruction);
  const [filteredConstructions, setFilteredConstructions] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  //Constructions
  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const host = JSON.parse(localStorage.getItem('userInfo')).hostName;
    $.ajax({
      method: 'GET',
      url: `${mainURL}construction/get-all?clientId=${clientId}`,
      contentType: 'application/json',
      headers: {
        Authorization: 'Bearer ' + token,
        hostname: host,
      },
    }).done((res) => {
      if (isSubscribed) {
        setConstructions(res);
      }
    });
    return () => (isSubscribed = false);
  }, [clientId, refresh]);

  //Dialog management
  const [createDialog, setCreateDialog] = useState(false);
  const [renameDialog, setRenameDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleCloseDialogs = () => {
    setRenameDialog(false);
    setDeleteDialog(false);
    //setUpdateDialog(false);
    setCreateDialog(false);
  };

  const handleOpenDialog = (dialog) => (event) => {
    switch (dialog) {
      case 'create':
        setCreateDialog(true);
        break;

      case 'delete':
        setDeleteDialog(true);
        break;

      case 'rename':
        setRenameDialog(true);
        break;

      default:
        console.log('None');
        break;
    }
  };

  const handleShowNotification = (severity, message) => {
    console.log(severity, message);
  };

  //Popover management
  const [anchor, setAnchor] = useState(null);
  const handlePopoverOpen = (selected) => (event) => {
    console.log(selected);
    event.stopPropagation();
    setAnchor(event.currentTarget);
    setSelectedConstruction(selected);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelect = (data) => () => {
    if (constructionId === data.id) navigate(`/clientes/${clientId}`);
    else {
      navigate(`/clientes/${clientId}/${data.id}`);
    }
  };

  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setSearchText(value);
  };

  useEffect(() => {
    const myReg = new RegExp('^.*' + searchText.toLowerCase() + '.*');
    const newArray = constructions.filter((f) => f.name.toLowerCase().match(myReg));
    setFilteredConstructions(newArray);
  }, [constructions, searchText]);

  return (
    <React.Fragment>
      <CreateConstructionDialog
        refresh={refresh}
        open={createDialog}
        setRefresh={setRefresh}
        handleClose={handleCloseDialogs}
        handleShowNotification={handleShowNotification}
      />
      <RenameConstructionDialog
        refresh={refresh}
        open={renameDialog}
        setRefresh={setRefresh}
        name={selectedConstruction.name}
        title={'Renombrar obra'}
        handleClose={handleCloseDialogs}
        renameURL={`construction/${selectedConstruction.id}/rename`}
        handleShowNotification={handleShowNotification}
      />
      <DeleteConstructionDialog
        refresh={refresh}
        open={deleteDialog}
        title={'Eliminar obra'}
        setRefresh={setRefresh}
        name={selectedConstruction.name}
        errorMessage={errorMessage}
        handleClose={handleCloseDialogs}
        deleteURL={`construction/${selectedConstruction.id}`}
        handleShowNotification={handleShowNotification}
      />
      <ConstructionPopover
        open={anchor}
        showDeleteOption
        showRenameOption
        handleClose={handlePopoverClose}
        setDeleteDialog={setDeleteDialog}
        setRenameDialog={setRenameDialog}
      />
      <Grid item sm={12} container justifyContent={'flex-end'} pb={2}>
        <SearchAndCreate
          buttonLabelTooltip={'Crear obra'}
          handleOpenDialog={handleOpenDialog}
          handleSearch={handleSearch}
          searchText={searchText}
          permission={14}
          options={options}
          title={'Buscar obra'}
        />
      </Grid>
      <Grid item sm={12} container spacing={1}>
        {filteredConstructions.map((construction) => (
          <ConstructionCard
            key={construction.id}
            construction={construction}
            handleSelect={handleSelect}
            handlePopoverOpen={handlePopoverOpen}
            selected={construction.id === constructionId}
          />
        ))}
      </Grid>
    </React.Fragment>
  );
}
