import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#BF213E',
    },
    secondary: {
      main: '#888C8C',
    },
  },
});

//#8C1127 Vino tinto
//#BF213E Rojo
//#590C29 Rojo/Café
//#888C8C Gris
//#0D0D0D Negro

export default theme;
